import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import AdminProjectSelector from './AdminProjectSelector'
import RegionSelector from './RegionSelector';
import constants from '../../config/constants';
import request from '../../_network/request';
import { coreServiceURLs } from '../../_network/apiUrls';
import { profileReset } from '../../store/reducers/profileSlice';
import { setCurrentLang } from '../../store/reducers/settingsSlice';
import { openStackConfigReset } from '../../store/reducers/openStackSlice';
import { textsReset, setLangTexts } from '../../store/reducers/textsSlice';
import { drawerStateReset } from '../../store/reducers/drawerSlice';
import { computeNovaApiUrlsReset } from '../../store/reducers/computeNovaSlice';
import { imageGlanceApiUrlsReset } from '../../store/reducers/imageGlanceSlice';
import { networkNeutronApiUrlsReset } from '../../store/reducers/networkNeutronSlice';
import { blockStorageCinderApiUrlsReset } from '../../store/reducers/blockStorageCinderSlice';
import { dnsDesignateApiUrlsReset } from '../../store/reducers/dnsDesignateSlice';
import { getTranslatedText } from '../../lang/translator';
import useWindowDimensions from './WindowDimensions';
import HeaderButton from './HeaderButton'
import SettingsSideDrawer from './SettingsSideDrawer'
import SettingsContent from './SettingsContent'
import HeaderBilling from './HeaderBilling'
import PlusButton from './PlusButton'
import BurgerMenu from './BurgerMenu';
import CustomDialog from './CustomDialog';

const NavBar = (props) => {
  const { width } = useWindowDimensions()

  const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
  const logo = useSelector(state => state.settings.logo)
  
  const adminProjects = useSelector(state => state.profile.adminProjects)
  const supportedRegions = useSelector(state => state.openstack.supportedRegions)
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const region_name = useSelector(state => state.settings.regionName)
  const domain = useSelector(state => state.settings.openstackDomain)
  const username = useSelector(state => state.profile.username)
  const defaultLang = useSelector(state => state.settings.defaultLang)
  const currentLang = useSelector(state => state.settings.currentLang)

  const navigate = useNavigate()
  const dispatch = useDispatch()  
  
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false)
  const [burgerDrawerOpen, setBurgerDrawerOpen] = useState(false)
  const [addProjectDialogOpen, setAddProjectDialogOpen] = useState(false)
  const [addProjectSuccessDialogOpen, setAddProjectSuccessDialogOpen] = useState(false)
  const [addProjectErrorDialogOpen, setAddProjectErrorDialogOpen] = useState(false)

  const sxLogoButton = logo ? {
    border: 'none', 
    background: 'none',
    '&:hover': {
      border: 'none', 
      background: 'none',
    }
  } : {
    borderColor: 'primary.main', 
    borderRadius: '50%',
    color: 'primary.main',
  }

  const handleLangChange = (value) => {
    dispatch(textsReset())

    if (defaultLang !== value) {
      getTranslatedText(value).then((translatedItems) => {
        let new_data = { ...defaultTexts, ...translatedItems }
        dispatch(setLangTexts({langTexts: new_data}))
        dispatch(setCurrentLang({currentLang: value}))
      })
    } else {
      dispatch(setCurrentLang({currentLang: value}))
    }
  }

  const handleHomeNavigation = () => {
    navigate('/')
    // dispatch(textsReset())
  }

  const logoutHandler = () => {
    dispatch(profileReset())
    dispatch(drawerStateReset())
    dispatch(openStackConfigReset())
    handleLangChange(currentLang)
    //dispatch(textsReset())
    dispatch(computeNovaApiUrlsReset())
    dispatch(networkNeutronApiUrlsReset())
    dispatch(blockStorageCinderApiUrlsReset())
    dispatch(imageGlanceApiUrlsReset())
    dispatch(dnsDesignateApiUrlsReset())
    navigate('/')
  }

  const handleOpenSettingsDrawer = () => {
    setSettingsDrawerOpen(true)
    handleCloseBurgerDrawer()
  }

  const handleCloseSettingsDrawer = () => {
    setSettingsDrawerOpen(false)
    if (isAuthenticated && width < 1024) {
      handleOpenBurgerDrawer()
    }
  }

  const handleOpenBurgerDrawer = () => {
    setBurgerDrawerOpen(true)
  }

  const handleCloseBurgerDrawer = () => {
    setBurgerDrawerOpen(false)
  }

  const handleAddProjectDialogOpen = () => {
    setAddProjectDialogOpen(true)
  }

  const handleAddProjectDialogClose = () => {
    setAddProjectDialogOpen(false)
  }

  const handleAddProjectSuccessDialogOpen = () => {
    setAddProjectSuccessDialogOpen(true)
  }

  const handleAddProjectSuccessDialogClose = () => {
    setAddProjectSuccessDialogOpen(false)
  }

  const handleAddProjectErrorDialogOpen = () => {
    setAddProjectErrorDialogOpen(true)
  }

  const handleAddProjectErrorDialogClose = () => {
    setAddProjectErrorDialogOpen(false)
  }

  const onSendNewProjectRequest = async () => {
    const postData = {
      group: 'openstack_admin',
      type: 'project_request',
      client_id: clientAccountID,
      alert_data: {
        name: username,
        region_name,
        domain,
      }
    }

    const url = `${constants.core_service_domain}/${coreServiceURLs.getOpenStackProjectRequest}`

    const request_data = {
      url,
      method: 'POST',
      data: postData,
    }

    const response = await request(request_data)
    
    if (response && response.status_code && response.status_code < 400) {
      handleAddProjectDialogClose()
      handleAddProjectSuccessDialogOpen()
    } else {
      handleAddProjectDialogClose()
      handleAddProjectErrorDialogOpen()
    }
  }

  return (
    <Container maxWidth="false">
      <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'flex-start'
        }}>
          <Button 
            onClick={handleHomeNavigation}
            variant="outlined"
            disableRipple
            sx={sxLogoButton}
          >
            { logo ? <img src={constants.s3URL + constants.s3Bucket + logo} style={{ maxHeight: '44px' }} alt='' /> : 'LOGO' }
          </Button>

          { (isAuthenticated && adminProjects.length > 0) && <AdminProjectSelector logoutHandler={logoutHandler} /> }

          { isAuthenticated && <PlusButton style={{ transform: 'scale(0.9)', color: 'white' }} onClick={handleAddProjectDialogOpen} /> }

          { (isAuthenticated && supportedRegions.length > 0) && <RegionSelector /> }
        </Box>        
        
        <Toolbar disableGutters>
          {/* {isAuthenticated && adminProjects.length > 0 && <AdminProjectSelector />} */}
          {/* <LangSwitcher /> */}
          {/* <UIModeButton /> */}

          {
            (isAuthenticated && width >= 1024) && 
            <>
              <HeaderBilling />

              {/* <HeaderButton
                buttontype='alerts'
                sx={{ marginLeft: '10px' }}
              />

              <HeaderButton
                buttontype='profile'
                sx={{ marginLeft: '10px' }}
              /> */}
            </>
          }

          {
            (!isAuthenticated || width > 1024) &&
            <HeaderButton
              buttontype='settings'
              sx={{ marginLeft: '10px' }}
              onClick={handleOpenSettingsDrawer}
            />
          }

          {
            (isAuthenticated && width >= 1024) && 
            <HeaderButton
              buttontype='logout'
              sx={{ marginLeft: '10px' }}
              onClick={logoutHandler}
            />
          }

          {
            (isAuthenticated && width < 1024) &&
            <HeaderButton
              buttontype='burger'
              sx={{ marginLeft: '10px' }}
              onClick={handleOpenBurgerDrawer}
            />
          }

          <SettingsSideDrawer
            drawerOpen={settingsDrawerOpen}
            handleOpenDrawer={handleOpenSettingsDrawer}
            handleCloseDrawer={handleCloseSettingsDrawer}
            drawerWidth='300'
          >
            <SettingsContent
              handleCloseSettingsDrawer={handleCloseSettingsDrawer}
            />
          </SettingsSideDrawer>
          
          <SettingsSideDrawer
            drawerOpen={burgerDrawerOpen}
            handleOpenDrawer={handleOpenBurgerDrawer}
            handleCloseDrawer={handleCloseBurgerDrawer}
            drawerWidth='300'
          >
            <BurgerMenu
              handleCloseBurgerDrawer={handleCloseBurgerDrawer}
              logoutHandler={logoutHandler}
              handleOpenSettingsDrawer={handleOpenSettingsDrawer}
            />
          </SettingsSideDrawer>

        </Toolbar>
      </Box>

      <CustomDialog
        open={addProjectDialogOpen}
        onClose={handleAddProjectDialogClose}
        dialogTitle={{
          title: defaultTexts.requestNewProject, 
          sx: {color: 'primary.main'}}}
        dialogBody={{
          text: defaultTexts.requestNewProjectText, 
          sx: {color: 'text.primary'}}}
        actionButtons={[{
          title: defaultTexts.sendRequest, 
          onClick: onSendNewProjectRequest, 
          sx: {color: 'primary.main'}}]}
      />

      <CustomDialog
        open={addProjectSuccessDialogOpen}
        onClose={handleAddProjectSuccessDialogClose}
        dialogTitle={{
          title: defaultTexts.requestNewProject, 
          sx: {color: 'primary.main'}}}
        dialogBody={{
          text: defaultTexts.requestNewProjectSuccessText, 
          sx: {color: 'text.primary'}}}
      />

      <CustomDialog
        open={addProjectErrorDialogOpen}
        onClose={handleAddProjectErrorDialogClose}
        dialogTitle={{
          title: defaultTexts.requestNewProject, 
          sx: {color: 'primary.main'}}}
        dialogBody={{
          text: defaultTexts.anErrorOccured, 
          sx: {color: 'text.primary'}}}
      />
    </Container>
  )
}

export default NavBar