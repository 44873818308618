import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
// import PTRRecordsWrapperV2 from './reverse_dns/ptrRecordsWrapperV2';
// import FloatingIPsWrapperV2 from './reverse_dns/floatingIPsWrapperV2';
import ZonesWrapperV2 from './zones/zonesWrapperV2';

const DNSDesignateContentV2 = (props) => {
	const theme = useTheme()
	const { section } = props

	const getServiceMenu = useCallback(() => {
		return [
			{ keyword: "submenuZones", parent: "/zones", navigation: "/zones", is_active: true, component: ZonesWrapperV2 },
			{ keyword: "submenuPTRRecords", parent: "/reverse-dns", navigation: "/ptr-records", is_active: false, component: undefined },
			{ keyword: "submenuFloaringIPs", parent: "/reverse-dns", navigation: "/floating-ips", is_active: false, component: undefined },
		]
	}, [])

	const getCurrentTab = useCallback(() => {
		const serviceMenu = getServiceMenu()
		const currentItem = section ? serviceMenu.filter(item => item.navigation === section)[0] : serviceMenu.filter(item => item.is_active)[0]
		return currentItem
	}, [section, getServiceMenu])

	const [currentTab, setCurrentTab] = useState(getCurrentTab())

	useEffect(() => {
		setCurrentTab(getCurrentTab())
	}, [getCurrentTab, section])
	
	return (
		<Box sx={{ background: theme.palette.customWhite, height: 'calc(100vh - 64px)' }}>
			<currentTab.component
				serviceMenu={getServiceMenu()}
			/>
		</Box>
	)
}

export default DNSDesignateContentV2