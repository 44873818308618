import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomText from '../../../../_common/CustomText';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import { FormGroup } from '@mui/material';
import { zoneDataForm, masterServersForm } from '../../../../../_data/openstack/dns/zones/v2';
import { getFormFieldComponent } from '../../../../_common/_form_fields/form_helpers';
import { openStackServices } from '../../../../../config/openStackConstants';
import { dnsDesignateConstants } from '../../../../../config/openStackConstants';
import { dnsDesignateRequest } from '../../../../../_network/openstack_request';
import { zonesUrl as zonesUrlResponses } from '../../../../../_api_responses/openstack/dns/zones/v2';
import CustomDialog from '../../../../_common/CustomDialog';
import AddButtonWithText from '../../../../_common/AddButtonWithText';

const SERVICE_NAME = openStackServices.dnsService

const ZoneAddV2 = (props) => {
  const token = useSelector(state => state.profile.x_auth_token)
  const [error, setError] = useState()
  const [successZoneAdd, setSuccessZoneAdd] = useState()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const { handleFetchData } = props
  const { defaultTexts } = props

  const [formData, setFormData] = useState({})
  const [formDataOptions, setFormDataOptions] = useState({});
  const [dnsMasters, setDNSMasters] = useState([]);
  const [formExtraFields, setFormExtraFields] = useState([]);
  const [zoneImportDialogOpen, setZoneImportDialogOpen] = useState(false)
  const [zoneImportContent, setZoneImportContent] = useState("")

  const dnsServiceDomain = useSelector(state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.service_domain)
  const dnsServiceVersion = useSelector(state => state.openstack.purchasedServices.filter(
      service => service.service === SERVICE_NAME)[0].config_params.api_version)
  const zonesUrl = useSelector(
      state => state.dnsDesignate.dnsDesignateApiUrls.filter(
          version => version.api_version === "v2")[0].urls.filter(
              url => url.keyword === dnsDesignateConstants.zonesUrl)[0].url)

  
  const handleDNSMasterFormDataChange = (event, field_key, index) => {
      let new_form_data = [...dnsMasters]
      new_form_data[index][field_key] = event.target.value.trim()
      setDNSMasters(new_form_data)
  }

  const handleDNSMasterAdd = () => {
      let new_form_data = {}
      new_form_data["masters"] = ""
      const updated_data = [...dnsMasters]
      updated_data.push(new_form_data)
      setDNSMasters(updated_data)
  }

  const handleDNSMasterRemove = () => {
      let new_data = [...dnsMasters]
      new_data.pop()
      setDNSMasters(new_data)
  }

  const handleFormExtraFieldsChange = (event,field_key_list) => {
      let new_extra_data = [...formExtraFields]
      if (field_key_list[1] === "key") {
          new_extra_data[field_key_list[0]].field_key = event.target.value
      } else {
          new_extra_data[field_key_list[0]].field_value = event.target.value
      }
      setFormExtraFields(new_extra_data)
  }

  const handleFormExtraFieldsRemove = (index) => {
      if (formExtraFields.length > 1) {
          let updated_data = [...formExtraFields]
          updated_data.splice(index, 1)
          setFormExtraFields(updated_data)
      } else {
          setFormExtraFields([])
      }
  }

  const handleFormDataValidation = () => {
      let validation_faild = true
      let updatedDataFormOptions = {...formDataOptions}
      for (let n in zoneDataForm) {
          if (zoneDataForm[n].required && !formData[zoneDataForm[n].field_key]) {
              if (formData.type !== "SECONDARY") {
                  validation_faild = false
                  updatedDataFormOptions[zoneDataForm[n].field_key] = {}
                  updatedDataFormOptions[zoneDataForm[n].field_key]["error"] = true
                  updatedDataFormOptions[zoneDataForm[n].field_key]["errorText"] = defaultTexts[zoneDataForm[n].error_label]
              } else if (zoneDataForm[n].field_key !== "email") {
                  validation_faild = false
                  updatedDataFormOptions[zoneDataForm[n].field_key] = {}
                  updatedDataFormOptions[zoneDataForm[n].field_key]["error"] = true
                  updatedDataFormOptions[zoneDataForm[n].field_key]["errorText"] = defaultTexts[zoneDataForm[n].error_label]
              }
          }
      }
      setFormDataOptions(updatedDataFormOptions)
      return validation_faild
  }

  const formattedFormData = () => {
      if (formData.type === "SECONDARY") {
          let new_data = {...formData}
          delete new_data.email
          delete new_data.ttl
          new_data["masters"] = dnsMasters.map(item => item.masters)
          return new_data
      } else {
          return formData
      }
  }

  const handleFormAttributesAddToZone = (data) => {
      let updated_data = {...data}
      updated_data["attributes"] = {}
      for (let i in formExtraFields) {
          if (formExtraFields[i].field_key.length > 0) {
              updated_data["attributes"][formExtraFields[i].field_key] = formExtraFields[i].field_value
          }
      }
      return updated_data
  }

  const onAddZone = async () => {
      const validateFormData = handleFormDataValidation()
      if (validateFormData) {
          const formatted_data = formattedFormData()
          const full_data = handleFormAttributesAddToZone(formatted_data)
          
          const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}`
          const method = "POST"
          const response = await dnsDesignateRequest({
              url:url, 
              method:method,
              data: full_data, 
              token,
          })
          if (response.status_code === zonesUrlResponses.post.success_response.status_code) {
              handleAddZoneFormReset()
              handleFetchData()
              setSuccessZoneAdd({
                  success_title: zonesUrlResponses.post.success_response.response_title, 
                  success_message: zonesUrlResponses.post.success_response.response_message
              })
          } else {
              const error_response = zonesUrlResponses.post.error_response.filter(
                  error_item => error_item.status_code === response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = zonesUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: response.error
                  }
                  setError(errorObject)
              }
          }          
          
      }
      return validateFormData
  }

  const handleErrorDialogClose = () => {
      setError(null);
      setErrorDialogOpen(false);
  }

  const handleSuccessDialogClose = () => {
      setSuccessZoneAdd(null);
      setSuccessDialogOpen(false);
  }

  const handleAddZoneFormReset = () => {
      setFormDataOptions({})
      let new_form_data = {}
      for (const n in zoneDataForm) {
          if (
              zoneDataForm[n].field_type === "string" || 
              zoneDataForm[n].field_type === "select"
              ) {
                  new_form_data[zoneDataForm[n].field_key] = zoneDataForm[n].default_value ? 
                  zoneDataForm[n].default_value : 
                  ""
          } else if (zoneDataForm[n].field_type === "integer") {
              new_form_data[zoneDataForm[n].field_key] = zoneDataForm[n].default_value ? 
              zoneDataForm[n].default_value : 
              0
          }
      }
      setFormData(new_form_data)
      setDNSMasters([])
      setFormExtraFields([])
  }

  const handleFormDataChange = (event,field_key) => {

      let new_form_data = {...formData}
      if (zoneDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "bool") {
          new_form_data[field_key] = event.target.checked
      } else if (zoneDataForm.filter(
          item => item.field_key === field_key)[0].field_type === "select") {
          new_form_data[field_key] = event
      } else {
          new_form_data[field_key] = event.target.value
      }
      setFormDataOptions({})
      setFormData(new_form_data)
  }

  useEffect(() => {
      setErrorDialogOpen(true)
  },[error]);

  useEffect(() => {
      setSuccessDialogOpen(true)
  },[successZoneAdd]);

  const handleZoneImportDialogClose = () => {
      setZoneImportContent("")
      setZoneImportDialogOpen(false)
  }

  const handleZoneImportContentChange = (event) => {
      setZoneImportContent(event.target.value)
  }

  const onZoneImport = async () => {
      
          const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/tasks/imports`
          const method = "POST"
          const response = await dnsDesignateRequest({
              url:url, 
              method:method,
              headers: {"Content-Type": "text/dns"},
              form_data: zoneImportContent, 
              token,
          })
          if (response.status_code === zonesUrlResponses.post.success_response.status_code) {
              handleZoneImportDialogClose()
              handleFetchData()
          } else {
              const error_response = zonesUrlResponses.post.error_response.filter(
                  error_item => error_item.status_code === response.status_code)
              if (error_response.length > 0) {
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: response.error
                  }
                  setError(errorObject)
              } else {
                  const error_response = zonesUrlResponses.post.error_response.filter(
                      error_item => error_item.status_code === "unknown")
                  const errorObject = {
                      error_title: error_response[0].response_title, 
                      error_message: error_response[0].response_message,
                      error_details: response.error
                  }
                  setError(errorObject)
              }
          }
      
  }

  useEffect(() => {
      if (Object.keys(formData).length === 0) {
          let new_form_data = {}
          for (const n in zoneDataForm) {
              if (
                  zoneDataForm[n].field_type === "string" || 
                  zoneDataForm[n].field_type === "select"
                  ) {
                      new_form_data[zoneDataForm[n].field_key] = zoneDataForm[n].default_value ? 
                      zoneDataForm[n].default_value : 
                      ""
              } else if (zoneDataForm[n].field_type === "integer") {
                  new_form_data[zoneDataForm[n].field_key] = zoneDataForm[n].default_value ? 
                  zoneDataForm[n].default_value : 
                  0
              }
          }
          setFormData(new_form_data)
      }
  },[formData]);

  const getDataForm = () => {
      let form = [...zoneDataForm]
      let new_form_data = {...formData}
      if (new_form_data.type === "SECONDARY") {
          form = form.filter(item => item.field_key !== "email" && item.field_key !== "ttl")
          delete new_form_data.email
          delete new_form_data.ttl
      }
      return (
          <FormGroup>
              
              {form.map(field => {
                  let form_field_options = {...formDataOptions[field.field_key]}
                  let new_field = {...field}
                  delete new_field.label
                  return (
                      getFormFieldComponent(
                          field,
                          new_form_data,
                          handleFormDataChange,
                          defaultTexts[field.label],
                          {   ...form_field_options,
                              ...new_field,
                              item_titles: defaultTexts
                          }
                      )
                  )
              })}
              {new_form_data.type === "SECONDARY" && dnsMasters.map((serv,index) => {
                  return (
                      <div key={index}>
                      <CustomText>{defaultTexts.mastersFormFieldLabel} {index + 1}</CustomText>
                          {masterServersForm.map(item => {
                              return (
                                  getFormFieldComponent(
                                      item,
                                      serv,
                                      handleDNSMasterFormDataChange,
                                      defaultTexts[item.label],
                                      {index: index}
                                  )
                              )
                          })}
                      </div>
                  )
              })}
              {new_form_data.type === "SECONDARY" && dnsMasters.length > 0 && <MinusButton 
                  style={{ transform: 'scale(0.7)' }}
                  sx={{height: 5}}
                  onClick={handleDNSMasterRemove}
              />}
              {new_form_data.type === "SECONDARY" && <Stack 
                  direction="row" 
                  spacing={2} 
                  alignItems="center"
                  onClick={handleDNSMasterAdd}
                  sx={{cursor: "pointer"}}
              >
                  <PlusButton 
                      style={{ transform: 'scale(0.7)' }}
                      sx={{height: 5}}
                      onClick={handleDNSMasterAdd}
                  />
                  <CustomText>
                      {defaultTexts.addDNSMasterFormFieldLabel}
                  </CustomText>
              </Stack>}
              <Stack 
                  direction="row" 
                  spacing={2} 
                  alignItems="center"
                  sx={{my: 3}}
              >
                  <CustomText size="h6">
                      {defaultTexts.attributesFormFieldLabel}
                  </CustomText>
                  <PlusButton onClick={() => setFormExtraFields([
                      ...formExtraFields,
                      {field_key: "",field_value: ""}
                  ])} />
              </Stack>
              {formExtraFields.map((item,index) => {
                  return (
                      <Stack
                          key={index}
                          direction="row" 
                          spacing={2} 
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{my: 1}}
                      >
                          <CustomTextField
                              currentValue={item.field_key} 
                              setCurrentValue={handleFormExtraFieldsChange}
                              field_key={[index,"key"]}
                              label={defaultTexts.keyFormFieldLabel}
                          />
                          <CustomTextField
                              currentValue={item.field_value} 
                              setCurrentValue={handleFormExtraFieldsChange}
                              field_key={[index,"value"]}
                              label={defaultTexts.valueFormFieldLabel}
                          />
                          <MinusButton sx={{width: 90}} onClick={
                              () => handleFormExtraFieldsRemove(index)}
                          />
                      </Stack>
                      
                  )
              })}
          </FormGroup>
      )
  }

  return (
    <>
      <AddButtonWithText 
        getDataForm={getDataForm}               
        onSubmit={onAddZone}
        formReset={handleAddZoneFormReset}
        customTexts={{
            title: defaultTexts.addZoneDialogTitle,
            description: defaultTexts.addZoneDialogDescriptionText
        }}
      />

      {
        successZoneAdd && <CustomDialog
            open={successDialogOpen}
            onClose={handleSuccessDialogClose}
            dialogTitle={{
                title: defaultTexts[successZoneAdd.success_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: defaultTexts[successZoneAdd.success_message], 
                sx: {color: 'text.primary'}}}
        />
      }
        
      {
        error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>Details:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
          />
        }

        <CustomDialog
            open={zoneImportDialogOpen}
            onClose={handleZoneImportDialogClose}
            dialogTitle={{
                title: defaultTexts.zoneImportTitleText, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: defaultTexts.zoneImportMessageText, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onZoneImport, 
                sx: {color: 'primary.main'}}]}
        >
            
            <CustomTextField 
                label={defaultTexts.zoneImportContentFormFieldLable}
                multiline={true}
                setCurrentValue={handleZoneImportContentChange}
                currentValue={zoneImportContent}
                sx={{mt: 3}}
            />
        </CustomDialog>
    </>
  )
}

export default ZoneAddV2