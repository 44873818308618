import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import Skeleton from '@mui/material/Skeleton'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import useWindowDimensions from '../../../_common/WindowDimensions'
import constants from '../../../../config/constants'
import { billingRequest } from '../../../../_network/openstack_request'
import { billingUrls } from '../../../../_network/apiUrls'
import { flavorDataSchema, volumeDataSchema, networkDataSchema, floatingIpsDataSchema, zonesDataSchema } from '../../../../_data/billing/billing/billing'
import { getCurrentMonthFirstDate, getCurrentMonthLastDate, addTimeToDate } from '../../helpers/dates'
import CustomTable from '../../../_common/CustomTable'
import CustomPopover from '../../../_common/CustomPopover'
import CustomSelectField from '../../../_common/_form_fields/CustomSelectField'

const rowsPerPageList = [
  { value: '10', keyword: '10' },
  { value: '25', keyword: '25' },
  { value: '50', keyword: '50' },
  { value: '100', keyword: '100' },
]

const ResourceDetailsTable = (props) => {
  const { resource, id, resourceDetailsHeight } = props
  const { width } = useWindowDimensions()

  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const accessToken = useSelector(state => state.profile.access_token)
  const xAuthToken = useSelector(state => state.profile.x_auth_token)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const billingAccountConfig = useSelector(state => state.profile.billingAccountConfig)

  const currencyName = (billingAccountConfig && billingAccountConfig.currency && billingAccountConfig.currency.name) ? (billingAccountConfig.currency.name).toUpperCase() : ''

  const [chargesData, setChargesData] = useState([])
  const [charges, setCharges] = useState([])
  const [startDate, setStartDate] = useState(getCurrentMonthFirstDate())
  const [endDate, setEndDate] = useState(getCurrentMonthLastDate())
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('10')
  const [dataSchema, setDataSchema] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const convertBytestoGB = (v) => {
    return Math.round(v / (1024 * 1024 * 1024))
  }

  const handleChangeStartDate = (value) => {
    setStartDate(value)
  }

  const handleChangeEndDate = (value) => {
    setEndDate(value)
  }

  const handleChangeCurrentPage = (event, value) => {
    setCurrentPage(value)
  }

  const handleChargesDataFormatting = useCallback(() => {
    const cutDataByPage = (current_data, page, count) => {
      let new_data = []

      current_data.forEach((item, index) => {
        if (index >= (page - 1)*count && index < (page - 1)*count + count) {
          new_data.push(item)
        }
      })

      return new_data
    }

    let formatted_data = chargesData.map((item) => {
      let new_item = { ...item }      
      
      new_item.total_cost = item.total_cost + ' ' + currencyName

      new_item.total_size = item.total_size + ' ' + defaultTexts.gblabel

      if (resource ==='flavor') {
        new_item.total_hours_active = item.total_hours.active
        new_item.total_hours_other = item.total_hours.other
      }

      if (resource === 'image') {
        new_item.total_size = convertBytestoGB(item.total_size) + ' ' + defaultTexts.gblabel
      }

      return new_item
    })

    setTotalPages(Math.ceil(formatted_data.length/rowsPerPage))

    formatted_data = cutDataByPage(formatted_data, currentPage, rowsPerPage)

    setCharges(formatted_data)
  } ,[chargesData, currencyName, rowsPerPage, currentPage, resource, defaultTexts])

  useEffect(() => {
    const getDataSchemaFromResource = () => {
      let result = []
  
      if (resource ==='flavor') {
        result = flavorDataSchema
      }
      
      if (resource ==='snapshot' || resource ==='backup' || resource ==='volume-type' || resource ==='image') {
        result = volumeDataSchema
      }
  
      if (resource === 'router' || resource === 'vpn') {
        result = networkDataSchema
      }
  
      if (resource ==='floating-ip') {
        result = floatingIpsDataSchema
      }

      if (resource ==='zone') {
        result = zonesDataSchema
      }
  
      return result
    }

    const fetchChargesData = async () => {
      setIsLoading(true)
      setDataSchema([])

      let filterArray = []

      if (resource === 'flavor') {
        filterArray = ['flavor_id=' + id]
      }
      
      if (resource === 'volume-type') {
        filterArray = ['volume_type_id=' + id]
      }

      if (startDate) {
        const startDateString = addTimeToDate(startDate, 'start')
        filterArray.push(`start_time=${encodeURIComponent(startDateString)}`)
      }

      if (endDate) {
        const endDateString = addTimeToDate(endDate, 'end')
        filterArray.push(`end_time=${encodeURIComponent(endDateString)}`)
      }

      let filterString = filterArray.join('&')
      if (filterString) {
        filterString = '/?' + filterString
      }      

      const url = constants.billing_url + '/' + resource + billingUrls.getResourceCharges + '/' + defaultAdminProject + filterString

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data) {
        setChargesData(response.data)
      } else {
        setChargesData([])
      }

      setDataSchema(getDataSchemaFromResource())
      setIsLoading(false)
    }

    fetchChargesData()
  }, [accessToken, xAuthToken, clientAccountID, defaultAdminProject, resource, id, startDate, endDate])

  useEffect(() => {
		if (chargesData.length > 0) {
			handleChargesDataFormatting()
		} else {
			setCharges([])
		}
	},[
		chargesData,
		handleChargesDataFormatting
	])

  useEffect(() => {
    setCurrentPage(1)
  }, [rowsPerPage])

  useEffect(() => {
    setStartDate(getCurrentMonthFirstDate())
    setEndDate(getCurrentMonthLastDate())
  }, [resource, id])

  const getFilter = () => {
    return (
      <Stack
        direction={ width >= 900 ? 'row' : 'column' }
        justifyContent="flex-start" 
        alignItems={ width >= 900 ? 'center' : 'flex-start' }
        sx={{
          width: '100%',
          padding: '10px 10px 0px 10px',
        }}
      >
        <Box>
          <DatePicker
            label=''
            value={new Date(startDate)}
            onChange={e => handleChangeStartDate(new Date(e))}
            maxDate={new Date(endDate)}
            slotProps={{ textField: { size: 'small' } }}
            sx={{ width: '160px' }}
          />
        </Box>
        <Box sx={{ margin: '0px 10px' }}>{ width >= 900 ? '-' : <Box sx={{ width: '1px', height: '10px' }}></Box> }</Box>
        <Box>
          <DatePicker
            label=''
            value={new Date(endDate)}
            onChange={e => handleChangeEndDate(new Date(e))}
            minDate={new Date(startDate)}
            slotProps={{ textField: { size: 'small' } }}
            sx={{ width: '160px' }}
          />
        </Box>
      </Stack>
    )
  }
  
  return (
    <>
      <>
        {
          width >= 900 ?
          <Box sx={{ paddingBottom: '10px' }}>
            {getFilter()}
          </Box> :
          <Box sx={{ paddingLeft: '10px' }}>
            <CustomPopover
              type='filterlist'
              horizontalOrigin='left'
            >
              {getFilter()}
            </CustomPopover>
          </Box>          
        }
      </>

      {
        isLoading ?
        <Stack spacing={2}>
          <Skeleton variant="rounded" width='90%' height={30} />
          <Skeleton variant="rounded" width='90%' height={30} />
          <Skeleton variant="rounded" width='90%' height={30} />
        </Stack> :
        <>
          <Box
            sx={{
              overflowY: 'auto',
              height: chargesData.length > 10 ? `${resourceDetailsHeight - 200}px` : `${resourceDetailsHeight - 180}px`,
              marginBottom: '5px',
            }}
          >
            <CustomTable
              withPagination={false}
              withCheckbox={false}
              withActions={false}
              withSorting={false}
              defaultDataTexts={{ ...defaultTexts }}
              tableHeaders={dataSchema}      
              columns={[...dataSchema]}
              handleRowSelection={() => {}}   
              dataRows={charges}
              setDataRows={setCharges}      
              selfSorting={true}
            />
          </Box>
          
          {
            chargesData.length > 10 &&
            <Stack
              spacing={1}
              direction={ width >= 900 ? 'row' : 'column' }
              justifyContent="flex-start" 
              alignItems={ width >= 900 ? 'center' : 'flex-start' }
              sx={{
                width: '100%',
                padding: '0px 10px 10px 10px',
              }}
            >
              <Box>
                <CustomSelectField
                  items={rowsPerPageList} 
                  currentValue={rowsPerPage}
                  setCurrentValue={setRowsPerPage}
                  self_item_titles={true}
                  empty={false}
                  size='small'
                  sx={{ width: '100px' }}
                />
              </Box>
              {
                totalPages > 1 &&
                <Box>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    boundaryCount={2}
                    onChange={handleChangeCurrentPage}
                  />
                </Box>
              }        
            </Stack>
          }
        </>
      }
    </>
  )
}

export default ResourceDetailsTable