export const flavorDataSchema = [
  {
    field_key: "name",
    is_id_field: false,
    field_type: "string", 
    label: "instanceFormFieldLabel",
  },
  {
    field_key: "total_hours_active",
    is_id_field: false,
    field_type: "string", 
    label: "totalHoursUsedActiveLabel",
  },
  {
    field_key: "total_hours_other",
    is_id_field: false,
    field_type: "string", 
    label: "totalHoursUsedOtherLabel",
  },
  {
    field_key: "total_cost",
    is_id_field: false,
    field_type: "string", 
    label: "totalAmountLabel",
  },
]

export const volumeDataSchema = [
  {
    field_key: "name",
    is_id_field: false,
    field_type: "string", 
    label: "nameFormFieldLabel",
  },
  {
    field_key: "total_hours",
    is_id_field: false,
    field_type: "string", 
    label: "totalHoursUsedLabel",
  },
  {
    field_key: "total_size",
    is_id_field: false,
    field_type: "string", 
    label: "totalSizeLabel",
  },
  {
    field_key: "total_cost",
    is_id_field: false,
    field_type: "string", 
    label: "totalAmountLabel",
  },  
]

export const networkDataSchema = [
  {
    field_key: "name",
    is_id_field: false,
    field_type: "string", 
    label: "nameFormFieldLabel",
  },
  {
    field_key: "total_hours",
    is_id_field: false,
    field_type: "string", 
    label: "totalHoursUsedLabel",
  },
  {
    field_key: "total_cost",
    is_id_field: false,
    field_type: "string", 
    label: "totalAmountLabel",
  },
]

export const floatingIpsDataSchema = [
  {
    field_key: "ip_address",
    is_id_field: false,
    field_type: "string", 
    label: "ipAddressFormFieldLabel",
  },
  {
    field_key: "total_hours",
    is_id_field: false,
    field_type: "string", 
    label: "totalHoursUsedLabel",
  },
  {
    field_key: "total_cost",
    is_id_field: false,
    field_type: "string", 
    label: "totalAmountLabel",
  },
]

export const zonesDataSchema = [
  {
    field_key: "name",
    is_id_field: false,
    field_type: "string", 
    label: "nameFormFieldLabel",
  },
  {
    field_key: "total_hours",
    is_id_field: false,
    field_type: "string", 
    label: "totalHoursUsedLabel",
  },
  {
    field_key: "total_cost",
    is_id_field: false,
    field_type: "string", 
    label: "totalAmountLabel",
  },
]