export const chargesSummarySchema = {
  compute_nova: { label: 'Compute Service', color: '#2731c8', },
  volumes_cinder: { label: 'Block Storage Service', color: '#60009b', },
  images_glance: { label: 'Images Service', color: '#b800d8', },
  networks_neutron: { label: 'Networks Service', color: '#2e96ff', },
  load_balancer: { label: 'Load Balancer Service', color: '#02b2af', },
  dns_designate: { label: 'DNS Service', color: '#6d4c41', },
}

export const serviceChargesLabels = {
  image: { label: 'Images', description: 'gbPerHourLabel' },
  volume_type: { label: 'Volume Types', description: '', childDescription: 'gbPerHourLabel' },
  snapshot: { label: 'Snapshots', description: 'gbPerHourLabel' },
  backup: { label: 'Backups', description: 'gbPerHourLabel' },
  router: { label: 'Routers', description: '' },
  floating_ip: { label: 'Floating IPs', description: 'ipAddressPerHourLabel' },
  fwaas_rule: { label: 'Firewall Rules', description: '', childDescription: 'rulesCountPerHourLabel' },
  vpn: { label: 'VPNs', description: 'ikeAndIpsecEncryptionAlgorithmsPerHourLabel' },
  flavor: { label: 'Flavors', description: '' },
  zone: { label: 'Zones', description: '' },
}

export const calculatorServicesSchema = [
  { label: 'Compute', value: 'compute_nova', },
  { label: 'Block Storage', value: 'volumes_cinder', },
  { label: 'Networks', value: 'networks_neutron', },
  { label: 'Images', value: 'images_glance', },
  { label: 'DNS', value: 'dns_designate', },  
]