import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Stack } from '@mui/material';
import { getTableCell } from './common_helpers';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CustomText from './CustomText';
import WrapperBox from './WrapperBox';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.customLightBlack,
    color: theme.palette.text.primary,
    fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.customLightBlack : undefined,

  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  //'&:last-child td, &:last-child th': {
  //  border: 0,
  //},
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 
    theme.palette.mediumGray  :
    theme.palette.customGray
  }
}));

const CustomTable = (props) => {
  const { dataRows, setDataRows } = props
  const { withCheckbox } = props
  const { withPagination }  = props
  const { withActions }  = props
  const { selfSorting, sortHandler } = props //
  const { tableHeaders } = props
  const { defaultDataTexts } = props
  const { identityField } = props
  const { rowsPerPage } = props
  const { handleRowSelection, handleCellClick } = props
  const { jsonFields } = props
  const { setCurrentAction } = props
  const { sortParams, setSelectedIds, deselectAll, setDeselectAll, disabledCheckboxes, isSuspended, showObjectInCell } = props
  
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const [numSelected, setNumSelected] = useState();
  const [numSelectedonCurrentPage, setNumSelectedonCurrentPage] = useState();
  
  const page = 0
  const [selectedRows, setSelectedRows] = useState([]);
  
  const [startItem, setStartItem] = useState(0);
  const [endItem, setEndItem] = useState(
    withPagination ? 
    rowsPerPage :
    dataRows.length);

  const [rowCount, setRowCount] = useState(dataRows.slice(startItem,endItem).length);
  const theme = useTheme();

  const sxSortIcon = {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginTop: '5px',
  }

  const handleTableRowClick = (index) => {
    if (!withCheckbox) {
      handleRowSelection(page*rowsPerPage + index)
    }
  }

  const handleTableCellClick = (index) => {
    if (withCheckbox) {
      if (handleRowSelection) {
        handleRowSelection(page*rowsPerPage + index)
      } else {
        const this_index = rowsPerPage ? page*rowsPerPage + index : index
        handleClick(undefined, dataRows[this_index])
      }
    }
  }
  
  const handleSelectAllClick = (event) => {
    let newSelected = dataRows.slice(startItem,endItem).map((n) => n[identityField]);
    
    if (disabledCheckboxes && disabledCheckboxes.length > 0) {
     newSelected = newSelected.filter(item => !disabledCheckboxes.includes(item))
    }

    if (event.target.checked) {
      setSelectedRows([
        ...new Set([
          ...selectedRows, 
          ...newSelected
        ])]);
    } else {
      setSelectedRows(selectedRows.filter(
        n => newSelected.indexOf(n) === -1));
    }
  };

  const handleClick = (event, item) => {
    const selectedIndex = selectedRows.indexOf(item[identityField]);
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = [...selectedRows, item[identityField]]
    } else {
      newSelected = selectedRows.filter(
        n => n !== item[identityField])
    }
    setSelectedRows(newSelected);
  };

  const handleSorting = (field_key,direction) => {
    if (selfSorting) {
      sortHandler(field_key,direction)
    } else {
      if (direction === "asc") {
        sortAscending(field_key)
      } else {
        sortDescending(field_key)
      }
    }
  }
  
  const isDescSorting = (field_key) => {
    if (!sortParams) {
      return false
    }
    
    const arr = sortParams.split('&&')
    
    if (!arr || arr.length === 0) {
      return false
    }
    
    const findKey = arr.find(item => (item === `sort_key=${field_key}` || item === `?sort_key=${field_key}`))
    const findDir = arr.find(item => item === 'sort_dir=desc')
    
    if (findKey && findDir) {
      return true
    }

    const arr1 = sortParams.split('&')
    
    if (!arr1 || arr1.length === 0) {
      return false
    }
    
    const findKey1 = arr1.find(item => (item === `sort_key=${field_key}` || item === `?sort_key=${field_key}`))
    const findDir1 = arr1.find(item => item === 'sort_dir=desc')
    
    if (findKey1 && findDir1) {
      return true
    }

    if (sortParams.includes(`=${field_key}:desc`)) {
      return true
    }

    return false
  }
  
  const sortAscending = (field_key) => {
    const compare = ( a, b ) => {
      if ( a[field_key] < b[field_key] ){
        return -1;
      }
      if ( a[field_key] > b[field_key] ){
        return 1;
      }
      return 0;
    }

    let sorted_data = [...dataRows]
    sorted_data.sort(compare)
    setDataRows(sorted_data)
  }

  const sortDescending = (field_key) => {
    const compare = ( a, b ) => {
      if ( a[field_key] < b[field_key] ){
        return -1;
      }
      if ( a[field_key] > b[field_key] ){
        return 1;
      }
      return 0;
    }

    let sorted_data = [...dataRows]
    sorted_data.sort(compare)
    sorted_data.reverse()
    setDataRows(sorted_data)
  }

  useEffect(() => {
    if (withPagination) {
      const start_item = page*rowsPerPage
      const end_item = page*rowsPerPage+rowsPerPage
      setStartItem(start_item)
      setEndItem(end_item)
      setRowCount(dataRows.slice(start_item,end_item).length)
    } else {
      setStartItem(0)
      setEndItem(dataRows.length)
      setRowCount(disabledCheckboxes ? dataRows.length - disabledCheckboxes.length : dataRows.length)
    }
  },[
    page,
    rowsPerPage,
    withPagination,
    dataRows,
    disabledCheckboxes
  ]);

  useEffect(() => {
    const countRowsPerPage = () => {
      const rowsOnCurrentPage = dataRows.slice(
        startItem,endItem).map((n) => n[identityField])
      const currentPageSelectedRows = selectedRows.filter(
        n => rowsOnCurrentPage.indexOf(n) !== -1).length
      setNumSelectedonCurrentPage(currentPageSelectedRows)
    }
    setNumSelected(selectedRows.length)
    countRowsPerPage()
    
  },[
    selectedRows,
    startItem,
    endItem,
    identityField,
    rowCount,
    dataRows
  ]);

  useEffect(() => {
    if (numSelected === 0 && withActions) {
      setCurrentAction("")
    }
  },[
    numSelected, 
    setCurrentAction,
    withActions
  ]);

  useEffect(() => {
    if (setSelectedIds) {
      setSelectedIds(selectedRows)
    }  
  }, [selectedRows, setSelectedIds])

  useEffect(() => {
    if (deselectAll && setDeselectAll) {
      setSelectedRows([])
      setDeselectAll(false)
    }
  }, [deselectAll, setDeselectAll])
  
if (dataRows.length === 0) {
    return (
      <Box sx={{ margin: '20px' }}>
        <WrapperBox
          sx={{
            border: 1,
            borderColor: "mediumGray",
            borderRadius: 2,
            height: 400,
            alignItems: 'center',
            justifyContent: 'center',
            py: 10,
          }}
        >
          <CustomText size="h5">{defaultTexts.noDataToDisplayText}</CustomText>
        </WrapperBox>
      </Box>      
    )
  }
  
  return (
    <>
      <TableContainer component={Paper} sx={{maxWidth: '100vw', borderRadius: '0px' }}>
        <Table sx={{ minWidth: 300}} size="small">
          <TableHead>
            <TableRow>
              {
                withCheckbox && 
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={ numSelectedonCurrentPage > 0 && numSelectedonCurrentPage < rowCount }
                    checked={rowCount > 0 && numSelectedonCurrentPage === rowCount}
                    disabled={isSuspended ? true : false}
                    onChange={handleSelectAllClick}
                  />
                </StyledTableCell>
              }

              {
                tableHeaders.map((header) => {
                  return (
                    <StyledTableCell key={header.field_key}>
                      {
                        header.sort_key ? (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ width: "100%" }}
                          >
                            <Box>
                              {
                                defaultDataTexts[header.label] ? 
                                defaultDataTexts[header.label] : 
                                header.label.toUpperCase()
                              }
                            </Box>
                            {
                              isDescSorting(header.sort_key) ? (
                                <Box sx={sxSortIcon} onClick={() => handleSorting(header.sort_key, "asc")}>
                                  <ArrowDropUpIcon />
                                </Box>
                              ) : (
                                <Box sx={sxSortIcon} onClick={() => handleSorting(header.sort_key, "desc")}>
                                  <ArrowDropDownIcon />
                                </Box>
                              )
                            } 
                          </Stack>
                        ) : (
                          <>
                            {
                              defaultDataTexts[header.label] ? 
                              defaultDataTexts[header.label] : 
                              header.label.toUpperCase()
                            }
                          </>
                        )
                      }
                    </StyledTableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              dataRows.slice(startItem, endItem).map((row,index) => {
                return (
                <StyledTableRow 
                  key={`${row[identityField]}_${index}`}
                  onClick={() => handleTableRowClick(index)}
                  sx={{ 
                    cursor: 'pointer'
                  }}
                >
                  {withCheckbox && <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedRows.indexOf(row[identityField]) !== -1}
                      onClick={(event) => handleClick(event, row)}
                      disabled={ ((disabledCheckboxes && disabledCheckboxes.includes(row.id)) || isSuspended) ? true : false }
                    />
                  </TableCell>}
                    {tableHeaders.map((header,ind) => {
                      let json_fields = undefined
                      if (header.field_type === "json") {
                        json_fields = jsonFields
                      }
                      return (
                        getTableCell({
                          fieldType: header.field_type,
                          value: row[header.field_key],
                          index: ind,
                          onClickFunction: handleCellClick && Object.keys(handleCellClick).includes(header.field_key) ? handleCellClick[header.field_key] : handleTableCellClick,
                          row: index,
                          textOnTrue: header.value_on_true ? defaultDataTexts[header.value_on_true] : defaultDataTexts.formValueYes,
                          textOnFalse: header.value_on_false ? defaultDataTexts[header.value_on_false] : defaultDataTexts.formValueNo,
                          listItemNumber: 3,
                          defaultTexts: defaultDataTexts,
                          formFields: json_fields,
                          showObjectInCell: showObjectInCell ? true : false,
                        })
                      )
                    })}
                </StyledTableRow>)
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CustomTable