import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import useWindowDimensions from '../../../_common/WindowDimensions'
import ResourceDetailsTable from './ResourceDetailsTable'
import ResourceDetailsGraph from './ResourceDetailsGraph'

const ResourceDetails = (props) => {
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const {
    resource,
    label,
    title,
    price,
    id,
    setSelectedResource,
    resourceDetailsHeight, 
  } = props

  const mode = useSelector(state => state.settings.uiMode)
  const drawerOpened = useSelector(state => state.drawer.drawerOpened)
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const billingAccountConfig = useSelector(state => state.profile.billingAccountConfig)

  const currencyName = (billingAccountConfig && billingAccountConfig.currency && billingAccountConfig.currency.name) ? (billingAccountConfig.currency.name).toUpperCase() : ''

  const containerStyle = {
    position: 'fixed',
    right: '0px',
    bottom: '0px',
    width: drawerOpened ? `${width - 270}px` : `${width - 65}px`,
    minHeight: `${resourceDetailsHeight}px`,
    background: mode === 'dark' ? theme.palette.customLightBlack : theme.palette.customWhite,
    borderTop: `${theme.palette.customGrayDark} 1px solid`,
  }

  const headerLabelStyle = {
    fontSize: '18px',
    color: theme.palette.secondary.main,
    fontWeight: '500',
  }

  const headerTitleStyle = {
    fontSize: '17px',
    color: theme.palette.primary.main,
    fontWeight: '500',
  }

  const headerPriceLabelStyle = {
    fontSize: '14px',
    color: theme.palette.customGrayDark,
  }

  const headerPriceAmountStyle = {
    fontSize: '15px',
  }

  const bodyContainerStyle = {
    padding: '10px 10px 10px 20px',
    background: mode === 'light' ? theme.palette.customGrayLight : theme.palette.background.default,
    borderTop: `${theme.palette.customGrayDark} 1px solid`,
  }

  const bodyItemStyle = {
    background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
    height: `${resourceDetailsHeight - 82}px`,
    width: '100%',
  }

  const handleCloseDetails = () => {
    setSelectedResource('')
  }
  
  return (
    <Box sx={containerStyle}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          width: '100%',
          height: '60px',
          padding: '0px 20px 0px 30px',
        }}
      >
        <Box>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
            sx={{ width: '100%' }}
            spacing={2}
          >
            <Box sx={headerLabelStyle}>{label}{ title ? ':' : '' }</Box>

            { title && <Box sx={headerTitleStyle}>{title}</Box> }

            {
              price &&
              <Box>
                <Box component='span' sx={headerPriceLabelStyle}>
                  <Box component='span'>
                    { (resource === 'flavor' || resource === 'router' || resource === 'zone') && defaultTexts.pricePerHourLabel }
                    { (resource === 'snapshot' || resource === 'backup' || resource === 'volume-type' || resource === 'image') && defaultTexts.gbPerHourLabel }
                    { resource === 'floating-ip' && defaultTexts.ipAddressPerHourLabel }
                    { resource === 'vpn' && defaultTexts.ikeAndIpsecEncryptionAlgorithmsPerHourLabel }
                  </Box>
                  <Box component='span'>: </Box>
                </Box>
                <Box component='span' sx={headerPriceAmountStyle}>{price} {currencyName}</Box>
              </Box>
            }            
          </Stack>
        </Box>
        <IconButton
          sx={{ color: theme.palette.primary.main }}
          onClick={handleCloseDetails}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <Box sx={bodyContainerStyle}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={12} lg={5}>
            <Box sx={bodyItemStyle}>
              <ResourceDetailsTable
                resource={resource}
                id={id}
                resourceDetailsHeight={resourceDetailsHeight}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={7}>
            <Box sx={bodyItemStyle}>
              <ResourceDetailsGraph
                resource={resource}
                id={id}
                resourceDetailsHeight={resourceDetailsHeight}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ResourceDetails