import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { dnsDesignateRequest } from '../../../../../_network/openstack_request';
import { zonesUrl as zonesUrlResponses } from '../../../../../_api_responses/openstack/dns/zones/v2';
import { zonesFilterMenu } from '../../../../../_data/openstack/dns/zones/v2';
import { openStackServices } from '../../../../../config/openStackConstants';
import { dnsDesignateConstants } from '../../../../../config/openStackConstants';
import ZonesTableV2 from './zonesTableV2';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import ZoneDetailV2 from './zoneDetailV2'
import Box from '@mui/material/Box';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import ZoneAddV2 from './zoneAddV2';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import CustomSplitPane from '../../../../_common/CustomSplitPane';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeselectIcon from '@mui/icons-material/Deselect'
import RefreshIcon from '@mui/icons-material/Refresh'
import SearchIcon from '@mui/icons-material/Search';
import CustomPopover from '../../../../_common/CustomPopover';
import SuspendedAlertDialog from '../../../../_common/SuspendedAlertDialog';

const SERVICE_NAME = openStackServices.dnsService

const ZonesWrapperV2 = (props) => {
	const theme = useTheme()
	const [isLoading, setIsLoading ] = useState(true);
	const [error, setError] = useState()
	const [success, setSuccess] = useState()
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [successDialogOpen, setSuccessDialogOpen] = useState(false);

	const token = useSelector(state => state.profile.x_auth_token)

	const filterMenu = zonesFilterMenu
	const [zonesData, setZonesData] = useState([]);
	const [zones, setZones] = useState([]);
	const { width } = useWindowDimensions();
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedZone, setSelectedZone] = useState(null);
	const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
	const [selectedFilterValue, setSelectedFilterValue] = useState("");
	const [filterQueryParams, setFilterQueryParams] = useState("")
	const [sortQueryParams, setSortQueryParams] = useState("");
	const [currentAction, setCurrentAction] = useState("");
	const [selectedZones, setSelectedZones] = useState([])
	const [zoneDeleteConfirmDialogOpen, setZoneDeleteConfirmDialogOpen] = useState(false);

	const defaultTexts = useSelector(state => state.texts.langTexts)
	const [fetchDataRequired, setFetchDataRequired] = useState(true);

	const [selectedIds, setSelectedIds] = useState([])
	const [deselectAll, setDeselectAll] = useState(false)

	const hasBilling = useSelector(state => state.profile.hasBilling)
	const billingDeposit = useSelector(state => state.profile.billingDeposit)	
	const isSuspended = (hasBilling && billingDeposit && ((billingDeposit.status && billingDeposit.status === 'suspended') || (billingDeposit.billing_type && billingDeposit.amount && billingDeposit.billing_type === 'prepaid' && billingDeposit.amount <= 0)))
	const [suspendedDialogOpen, setSuspendedDialogOpen] = useState(false)

	const dnsServiceDomain = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.service_domain)
	const dnsServiceVersion = useSelector(
			state => state.openstack.purchasedServices.filter(
			service => service.service === SERVICE_NAME)[0].config_params.api_version)
	const zonesUrl = useSelector(
			state => state.dnsDesignate.dnsDesignateApiUrls.filter(
					version => version.api_version === "v2")[0].urls.filter(
							url => url.keyword === dnsDesignateConstants.zonesUrl)[0].url)

	const handleZoneDataFormatting = useCallback((data) => {

			const formatted_data = data.map(item => {
					let new_item = {...item}
					
					return new_item
			})

			setZonesData(formatted_data)
	},[])

	const handleDataFetch = () => {
			setFetchDataRequired(true)
	}

	const getSearchType = () => {
		const filterObject = zonesFilterMenu.find(item => item.value === selectedFilter)
		return filterObject ? filterObject.type : ''
	}

	const getSearchSelectItems = () => {
		const filterObject = zonesFilterMenu.find(item => item.value === selectedFilter)
		return filterObject ? filterObject.items : []
	}

	const handleFilteredSearch = () => {
		if (selectedFilter && selectedFilterValue) {
			setFilterQueryParams(`${selectedFilter}=${selectedFilterValue}`)
		} else {
			setFilterQueryParams("")
		}
		handleDataFetch()
	}

	const handleZoneSorting = (field, direction) => {
			let new_query = `&sort_key=${field}&sort_dir=${direction}`
			setSortQueryParams(new_query)
			handleDataFetch()
	}

	const handleFilterReset = () => {
			setSelectedFilter(filterMenu[0].value)
			setSelectedFilterValue("")
			setFilterQueryParams("")
			handleDataFetch()
	}

	const handleErrorDialogClose = () => {
			setError(null);
			setErrorDialogOpen(false);
	}

	const handleSuccessDialogClose = () => {
			setSuccess(null);
			setSuccessDialogOpen(false);
	}

	const handleDetailCardOpen = useCallback((index) => {
			setSelectedZone(zonesData[index].id)
			setSelectedRow(zonesData[index])
	},[zonesData]);

	const handleDetailCardClose = () => {
			setSelectedZone(null)
			setSelectedRow(null)
	};

	const onZoneDeleteConfirm = (n_list) => {
			const selected_n_list = zonesData.filter(n => 
					n_list.includes(n.id))
			setSelectedZones([...selected_n_list])
			setZoneDeleteConfirmDialogOpen(true)
	}

	const handleZoneDeleteConfirmDialogClose = () => {
			setZoneDeleteConfirmDialogOpen(false)
	}

	const handleDeleteZoneAction = async (zone_id,token) => {
			const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/${zone_id}`
			const method = "DELETE"
			const response = await dnsDesignateRequest({url:url, method:method, token: token})
			if (response.status_code === zonesUrlResponses.delete.success_response.status_code) {
					return null
			} else {
					return response.error
			}
	}

	const onZoneDelete = async (zone_list) => {
			handleZoneDeleteConfirmDialogClose()
			let err = []
			
			for (let n in zone_list) {
					const resp = await handleDeleteZoneAction(zone_list[n].id,token)
					if (resp !== null) {
							err = [...err, resp]
					}
			}
			
			handleDetailCardClose()
			setCurrentAction("")
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			}
	}

	const handleExportZoneAction = async (zone_id,token) => {
			const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/${zone_id}/tasks/export`
			const method = "POST"
			const response = await dnsDesignateRequest({url:url, method:method, token: token})
			if (response.status_code === zonesUrlResponses.post.success_response.status_code) {
					return null
			} else {
					return response.error
			}
	}

	const handleZoneExport = async (z_list) => {
			handleLoading(true)
			let err = []
			
					for (let z in z_list) {
							const resp = await handleExportZoneAction(z_list[z],token)
							if (resp !== null) {
									err = [...err, resp]
							}
					}
			
			handleLoading(true)
			setCurrentAction("")
			handleDataFetch()
			if (err.length > 0) {
					let error_object = {}
					error_object["error_title"] = "errorDeleteRecordTitle"
					error_object["error_message"] = "errorDeleteRecordMessage"
					error_object["error_details"] = err.toString()
					setError(error_object)
					setErrorDialogOpen(true)
			} else {
					let success_object = {}
					success_object["success_title"] = "successZoneExportTitle"
					success_object["success_message"] = "successZoneExportMessage"
					setSuccess(success_object)
					setSuccessDialogOpen(true)
			}
	}

	const getZonesActionsList = () => {
			let zone_actions = []
			let new_action = {}

			new_action["value"] = "zone_delete"
			new_action["action"] = onZoneDeleteConfirm
			new_action["keyword"] = "zoneDeleteActionLabel"
			new_action["button_text"] = "applyButtonTitleText"
			zone_actions.push({...new_action})

			new_action = {}

			new_action["value"] = "zone_export"
			new_action["action"] = handleZoneExport
			new_action["keyword"] = "zoneExportActionTitle"
			new_action["button_text"] = "applyButtonTitleText"
			zone_actions.push({...new_action})
			
			return zone_actions
	}

	const handleLoading = (mode) => {
			setIsLoading(mode)
	}

	useEffect(() => {
			setFetchDataRequired(true)
	},[filterQueryParams,sortQueryParams]);

	useEffect(() => {
			if (fetchDataRequired) {
			(async () => {
					handleLoading(true)
					
					const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/?${filterQueryParams}${sortQueryParams}`
					const method = "GET"
					
					const response = await dnsDesignateRequest({url:url, method:method, token })
					
					if (response.status_code === zonesUrlResponses.get.success_response.status_code) {
							//handleZoneDataFormatting(response.data.zones)
							setZones(response.data.zones)
							if (selectedZone) {
									const selected_zone = response.data.zones.filter(item => item.id === selectedZone)
									if (selected_zone.length > 0) {
											setSelectedRow(selected_zone[0])
									}
							}
					} 
					
			})();
			}
			setFetchDataRequired(false)
			setTimeout(()=>{handleLoading(false)},1000)
	},[
			dnsServiceDomain, 
			dnsServiceVersion, 
			zonesUrl, 
			selectedZone,
			filterQueryParams,
			fetchDataRequired,
			sortQueryParams,
			handleZoneDataFormatting,
			selectedRow,
			token,
	]);

	useEffect(() => {
			handleZoneDataFormatting(zones)
	},[
			zones,
			handleZoneDataFormatting
	])

	useEffect(() => {
		setSelectedFilterValue('')
	}, [selectedFilter])

	const handleActionRun = () => {
		const actionsList = getZonesActionsList()
		const action_func = actionsList.filter(action => action.value === currentAction)[0].action
		action_func(selectedIds)
	}

	const handleDeselectAll = () => {
		setSelectedIds([])
		setSelectedZones([])
		setDeselectAll(true)
	}

	const getActionButtons = () => {
		return (
			<Stack 
				direction={ width >= 900 ? 'row' : 'column' }
				justifyContent="space-between" 
				alignItems={ width >= 900 ? 'flex-end' : 'flex-start' }
			>
				{
					selectedIds.length > 0 && (
						<>
							<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
								<CustomSelectField
									items={getZonesActionsList()} 
									currentValue={currentAction}
									setCurrentValue={setCurrentAction}
									item_titles={defaultTexts}
									label={defaultTexts.actionsButtonText}
									empty={true}
									size="small"
								/>
							</Box>
							{
								currentAction &&
								<Button 
									variant="contained"
									sx={ width >= 900 ? { height: '40px', marginLeft: '10px' } : { height: '40px', marginBottom: '10px' } }
									onClick={handleActionRun}
								>
									{defaultTexts.applyButtonTitleText}
								</Button>
							}
						</>
					)
				}

				<ZoneAddV2
					defaultTexts={defaultTexts}
					handleFetchData={handleDataFetch}
				/>
			</Stack>
		)
	}

	const getFilter = () => {
		return (
			<>
				<Box sx={ width >= 900 ? { marginLeft: '0px' } : { marginBottom: '10px' } }>
					{
						getSearchType() === 'text' &&
						<CustomTextField 
							size="small"
							variant="outlined"
							currentValue={selectedFilterValue}
							setCurrentValue={e => setSelectedFilterValue(e.target.value)}
							sx={{ width: '240px' }}
						/>
					}

					{
						getSearchType() === 'select' &&
						<CustomSelectField
							items={getSearchSelectItems()} 
							currentValue={selectedFilterValue}
							setCurrentValue={setSelectedFilterValue}
							item_titles={defaultTexts}
							empty={true}
							size="small"
							sx={{ width: '240px' }}
						/>
					}
				</Box>
				<Box sx={ width >= 900 ? { marginLeft: '10px' } : { marginBottom: '10px' } }>
					<CustomSelectField
						items={filterMenu} 
						currentValue={selectedFilter}
						setCurrentValue={setSelectedFilter}
						item_titles={defaultTexts}
						empty={true}
						size="small"
						sx={{ width: '240px' }}
					/>
				</Box>
				
				<Stack
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
				>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
							marginLeft: width >= 900 ? '5px' : '0px',
						}}
						onClick={handleFilteredSearch}
					>
						<SearchIcon />
					</IconButton>
					<IconButton
						sx={{
							color: theme.palette.primary.main,
						}}
						onClick={handleFilterReset}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>				
			</>
		)
	}

	const getDnsHeader = () => {
		return (
			<>
				<Stack 
					direction="row" 
					justifyContent="space-between" 
					alignItems="center"
					sx={{
						padding: '20px 20px 5px 20px',
						width: "100%"
					}}
				>
					<Box
						sx={{
							fontSize: '17px',
							color: theme.palette.primary.main,
						}}
					>
						{defaultTexts.submenuZones} ({selectedIds.length}/{zonesData.length})
					</Box>
					
					<Box>
						{
							width >= 900 ?
							getActionButtons() :
							<CustomPopover
								type='menu'
								horizontalOrigin='right'
							>
								{getActionButtons()}
							</CustomPopover>
						}
					</Box>
				</Stack>				

				<Stack 
					direction="row" 
					justifyContent="flex-start" 
					alignItems="center"
					sx={{
						padding: '0px 20px 5px 10px',
						width: "100%"
					}}
				>
					<IconButton
						onClick={handleDeselectAll}
						disabled={selectedIds.length === 0 ? true : false}
					>
						<DeselectIcon />
					</IconButton>
					{
						width >= 900 ?
						getFilter() :
						<CustomPopover
							type='filterlist'
							horizontalOrigin='left'
						>
							<Stack
								direction="column" 
								justifyContent="flex-start" 
								alignItems="flex-start"
							>
								{getFilter()}
							</Stack>
						</CustomPopover>
					}
				</Stack>
			</>
		)
	}

	const getDnsTable = () => {
		return (
			<ZonesTableV2 
				zonesData={zonesData}
				setZonesData={setZonesData}
				handleRowSelection={handleDetailCardOpen}
				currentAction={currentAction}
				setCurrentAction={setCurrentAction}
				actionsTexts={defaultTexts}
				actionsList={getZonesActionsList()}
				selfSorting={true}
				sortHandler={handleZoneSorting}
				sortParams={sortQueryParams}
				setSelectedIds={setSelectedIds}
				deselectAll={deselectAll}
				setDeselectAll={setDeselectAll}
				isSuspended={isSuspended}
			/>
		)
	}

	const getDnsContent = () => {
		return (
			<>
				{ getDnsHeader() }
				{ getDnsTable() }
			</>
		)
	}	

	const getDnsDetails = () => {
		return (
			<ZoneDetailV2
				selectedRow={selectedRow}
				handleDataFetch={handleDataFetch}
				handleDelete={onZoneDeleteConfirm}
				handleZoneExport={handleZoneExport}
				setSelectedRow={setSelectedRow}
				setSelectedZone={setSelectedZone}
				isSuspended={isSuspended}
				setSuspendedDialogOpen={setSuspendedDialogOpen}
			/>
		)
	}

	return (
		<>
			{
				selectedRow !== null &&
				<CustomSplitPane contentTop={getDnsContent()} contentBottom={getDnsDetails()} />
			}

			<Box>
				{ isLoading && <CustomBackdrop open={isLoading} /> }

				{ selectedRow === null && getDnsHeader() }

				{ (selectedRow === null && !isLoading) && getDnsTable() }

				{
					error &&
					<CustomDialog
						open={errorDialogOpen}
						onClose={handleErrorDialogClose}
						dialogTitle={{
								title: defaultTexts[error.error_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `<span>${defaultTexts[error.error_message]}</span>
												<br>
												<br>
												<span>Details:</span> 
												<span style="color: orange">
														${error.error_details}
												</span>`, 
								sx: {color: 'text.primary'}}}
					/>
				}

				{
					success &&
					<CustomDialog
						open={successDialogOpen}
						onClose={handleSuccessDialogClose}
						dialogTitle={{
								title: defaultTexts[success.success_title], 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: defaultTexts[success.success_message], 
								sx: {color: 'text.primary'}}}
					/>
				}

				<CustomDialog
						open={zoneDeleteConfirmDialogOpen}
						onClose={handleZoneDeleteConfirmDialogClose}
						dialogTitle={{
								title: defaultTexts.zoneDeleteConfirmTitle, 
								sx: {color: 'primary.main'}}}
						dialogBody={{
								text: `${defaultTexts.zoneDeleteConfirmText}: [${selectedZones.map(v => v.name).toString()}]`, 
								sx: {color: 'text.primary'}}}
						actionButtons={[{
								title: defaultTexts.confirmButtonText, 
								onClick: () => onZoneDelete(selectedZones), 
								sx: {color: 'primary.main'}}]}
				/>

				<SuspendedAlertDialog
					isOpen={suspendedDialogOpen}
					setIsOpen={setSuspendedDialogOpen}
				/>
			</Box>
		</>
	)
}

export default ZonesWrapperV2