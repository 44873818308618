import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { chargesSummarySchema, serviceChargesLabels } from '../../helpers/billingHelpers'

const BillingTableRow = (props) => {
  const theme = useTheme()
  const { row, level, service, serviceResource, handleClickResource } = props
  const [open, setOpen] = useState(false)

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const billingAccountConfig = useSelector(state => state.profile.billingAccountConfig)

  const currencyName = (billingAccountConfig && billingAccountConfig.currency && billingAccountConfig.currency.name) ? (billingAccountConfig.currency.name).toUpperCase() : ''

  const descriptionStyle = {
    color: theme.palette.customGrayDark
  }

  const resourceStyle = {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'none',
    }
  }

  const convertMBtoGB = (v) => {
    if (v < 1024) {
      return v + ' MB'
    } else {
      return Math.round(v / 1024) + ' GB'
    }
  }

  const convertBytestoGB = (v) => {
    return Math.round(v / (1024 * 1024 * 1024))
  }

  const getServiceName = () => {
    return chargesSummarySchema[row.service_name] ? chargesSummarySchema[row.service_name].label : row.service_name
  }

  const getResourceName = () => {
    if (row.resource_name) {
      return serviceChargesLabels[row.resource_name] ? serviceChargesLabels[row.resource_name].label : row.resource_name
    }
  }

  const getResourceDescription = () => {
    let description = ''
    
    if (serviceResource === 'flavor') {
      const price = (row.data && row.data.price && row.data.price.active) ? ' - ' + row.data.price.active + ' ' + currencyName : ''
      description = row.data.cpu + ' CPUs, ' + convertMBtoGB(row.data.ram) + ' RAM, ' + convertMBtoGB(row.data.disk) + ' Disk' + price
    } else if (row.resource_name) {
      const price = (row.data && row.data.price) ? ' - ' + row.data.price + ' ' + currencyName : ''
      description = (serviceChargesLabels[row.resource_name] && serviceChargesLabels[row.resource_name].description) ? defaultTexts[serviceChargesLabels[row.resource_name].description] + price : ''
      if (!description && price) {
        description = price.replace(' - ', '')
      }
    } else if (row.data.name || row.rule_range) {
      const price = (row.data && row.data.price) ? ' - ' + row.data.price + ' ' + currencyName : ''
      description = (serviceResource && serviceChargesLabels[serviceResource] && serviceChargesLabels[serviceResource].childDescription) ? defaultTexts[serviceChargesLabels[serviceResource].childDescription] + price : ''
    }

    return description ? ' (' + description + ')' : ''
  }

  const onClick = () => {
    if (row.rule_range) {
      return
    }
    
    let resourceData = {}

    if (serviceResource === 'flavor') {
      resourceData = {
        resource: 'flavor',
        label: 'Compute Service Flavor',
        title: row.data.name,
        price: row.data.price.active,
        id: row.flavor_id
      }
    } else if (serviceResource === 'volume_type') {
      resourceData = {
        resource: 'volume-type',
        label: 'Block Storage Service Volume Type',
        title: row.data.name,
        price: row.data.price,
        id: row.volume_type_id
      }
    } else if (row.resource_name) {
      let service_name = ''

      if (row.resource_name === 'snapshot' || row.resource_name === 'backup') {
        service_name = chargesSummarySchema.volumes_cinder.label + ' '
      }

      if (row.resource_name === 'floating_ip' || row.resource_name === 'vpn' || row.resource_name === 'router') {
        service_name = chargesSummarySchema.networks_neutron.label + ' '
      }

      if (row.resource_name === 'image') {
        service_name = chargesSummarySchema.images_glance.label + ' '
      }

      if (row.resource_name === 'zone') {
        service_name = chargesSummarySchema.dns_designate.label + ' '
      }
      
      resourceData = {
        resource: (row.resource_name).replace('_', '-'),
        label: service_name + ' - ' + getResourceName(),
        price: row.data.price ? row.data.price : '',
      }
    }

    handleClickResource(resourceData)
  }
  
  return (
    <>
      {
        row.data ? (
          <TableRow>
            <TableCell sx={{ width: `${(level + 1) * 30}px`, }}></TableCell>
            <TableCell>
              <Box component='span' sx={row.rule_range ? {} : resourceStyle} onClick={onClick}>
                { getResourceName() }
                { row.data.name && row.data.name }
                { (row.rule_range && row.rule_range.length === 2) && defaultTexts.rulesRangeLabel + ' ' + row.rule_range[0] + ' - ' + row.rule_range[1] }
              </Box>
              <Box component='span' sx={descriptionStyle}>
                { getResourceDescription() }
              </Box>              
            </TableCell>
            <TableCell sx={{ width: '30%' }}>
              { service === 'volumes_cinder' && row.data.total_size + ' ' + defaultTexts.gblabel }
              { service === 'images_glance' && convertBytestoGB(row.data.total_size) + ' ' + defaultTexts.gblabel }
              { service === 'compute_nova' && row.data.total_hours.active + ' ' + defaultTexts.hrsLabel }              
              {
                service === 'networks_neutron' &&
                <>
                  {
                    (serviceResource && serviceResource === 'fwaas_rule') ?
                    row.data.total_hours + ' ' + defaultTexts.hrsLabel + ' (' + row.data.max_count + ' ' + defaultTexts.maxRulesLabel + ')' :
                    row.data.total_hours + ' ' + defaultTexts.hrsLabel
                  }
                </>
              }
              { service === 'dns_designate' && row.data.total_hours + ' ' + defaultTexts.hrsLabel }
            </TableCell>
            <TableCell align='right' sx={{ width: '20%' }}>
              { row.data.total_cost } {currencyName}
            </TableCell>
          </TableRow>
        ) : (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
              <TableCell
                sx={{
                  width: `${level * 30}px`,
                  paddingLeft: `${level * 30 - 14}px`
                }}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  { open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row" colSpan={2}>
                { level === 1 && getServiceName() }
                { row.region_name && row.region_name }
                { getResourceName() }
              </TableCell>
              <TableCell align='right' sx={{ width: '20%', fontWeight: level === 1 ? '600' : '400' }}>
                { row.sum ? (row.sum).toFixed(2) : '0' } {currencyName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  padding: '0px',
                  borderBottom: 'none',
                }}
                colSpan={4}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box>
                    <Table size="small">
                      <TableBody>
                        {
                          row.regions && row.regions.map((item, index) => (
                            <BillingTableRow
                              key={index}
                              row={item}
                              level={2}
                              service={row.service_name}
                              handleClickResource={handleClickResource}
                            />
                          ))
                        }            

                        {
                          row.service_resources && row.service_resources.map((item, index) => (
                            <BillingTableRow
                              key={index}
                              row={item}
                              level={3}
                              service={service}
                              handleClickResource={handleClickResource}
                            />
                          ))
                        }

                        {
                          row.resources && row.resources.map((item, index) => (
                            <BillingTableRow
                              key={index}
                              row={item}
                              level={4}
                              service={service}
                              serviceResource={row.resource_name}
                              handleClickResource={handleClickResource}
                            />
                          ))
                        }
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        )
      }
    </>
  )
}

export default BillingTableRow