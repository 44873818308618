import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, alpha } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const ZoneSpecsV2 = (props) => {
	const theme = useTheme()
	const { zoneData } = props
	const defaultTexts = useSelector(state => state.texts.langTexts);
	const mode = useSelector(state => state.settings.uiMode)

	const detailTabCardConfig = {
    background: mode === 'light' ? theme.palette.customWhite : alpha(theme.palette.customLightBlack, 0.8),
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
    padding: '20px 0px',
    minHeight: '410px',
    justifyContent: 'flex-start',
}

	return (
			<React.Fragment>
					<Grid container spacing={2} sx={{mt: 1, mb: 4}}>
							<Grid item xs={12} lg={6}>
									<WrapperBox
											sx={detailTabCardConfig}
									>
											<Stack direction="column" spacing={1} sx={{width: "90%"}}>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.idFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData ? zoneData.id : ""}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.nameFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.name ? zoneData.name : ""}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.projectFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.project_id ? 
																			zoneData.project_id : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.emailFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.email ? zoneData.email : "-"}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.statusFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.status}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.typeFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.type}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.poolFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.pool_id}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.serialNumberFormFieldLabel}
															</CustomText>
															{zoneData.serial}
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.descriptionFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.description ? zoneData.description : "-"}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
							<Grid item xs={12} lg={6}>
									<WrapperBox
											sx={detailTabCardConfig}
									>
											<Stack 
													direction="column" 
													spacing={1} 
													sx={{width: "90%"}}
											>
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.ttlFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.ttl}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.sharedFormFieldLabel}
															</CustomText>
															{zoneData.shared  ? defaultTexts.formValueYes : defaultTexts.formValueNo}
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.actionFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.action}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.versionFormFieldLabel}
															</CustomText>
															{zoneData.version}
													</Stack>
													<Divider />
													{zoneData.type === "SECONDARY" && <Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.mastersFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.masters}
															</CustomText>
													</Stack>}
													{zoneData.type === "SECONDARY" && <Divider />}
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.createTimeHeaderLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(zoneData.created_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.updatedAtFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{new Date(zoneData.updated_at).toLocaleString()}
															</CustomText>
													</Stack>
													<Divider />
													<Stack 
															direction="row" 
															justifyContent="space-between" 
															alignItems="center"
													>
															<CustomText 
																	size="p" 
																	sx={{color: "text.secondary"}}
															>
																	{defaultTexts.transferedAtFormFieldLabel}
															</CustomText>
															<CustomText size="p">
																	{zoneData.transfered_at ? new Date(zoneData.transfered_at).toLocaleString() : "-"}
															</CustomText>
													</Stack>
													<Divider />
											</Stack>
									</WrapperBox>
							</Grid>
					</Grid>
			</React.Fragment>
	)
}

export default ZoneSpecsV2