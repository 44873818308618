import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from '../../_common/CustomDrawer';
import IncorrectServiceVersionNote from '../../_common/IncorrectServiceVersionNote';
import NotPurchasedService from '../../_common/NotPurchasedService';
import { dnsDesignateConstants } from '../../../config/openStackConstants';
import DNSDesignateContentV2 from './v2/dnsDesignateContentV2';
import { openStackServices } from '../../../config/openStackConstants';

const SERVICE_NAME = openStackServices.dnsService
const SERVICE_TITLE = 'DNS (Designate)'

const DNSDesignateWrapper = (props) => {
	const { section } = props
	const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
	const purchasedServices = useSelector(state => state.openstack.purchasedServices)
	const navigate = useNavigate();

	if (!isAuthenticated) {
		navigate('/');
	}	

	const purchasedService = purchasedServices.filter(item => item.service === SERVICE_NAME)

	if (purchasedService.length === 0) {
		return (
			<CustomDrawer>
				<NotPurchasedService service={SERVICE_TITLE} />
			</CustomDrawer>
		)
	}

	if (!purchasedService[0].config_params.visible_to_service.includes('custom_client_ui')) {
		return (
			<CustomDrawer>
				<NotPurchasedService service={SERVICE_TITLE} />
			</CustomDrawer>
		)
	}

	if (!dnsDesignateConstants.supported_api_versions.includes(
		purchasedService[0].config_params.api_version)) {
		return (
			<CustomDrawer>
				<IncorrectServiceVersionNote 
					service_title={SERVICE_TITLE} 
					service_version={purchasedService[0].config_params.api_version}
				/>
			</CustomDrawer>
		)
	}

	if (purchasedService[0].config_params.api_version === "v2") {
		return (
			<CustomDrawer>
				<DNSDesignateContentV2 
					serviceTitle={SERVICE_TITLE} 
					serviceName={SERVICE_NAME}
					section={section}
				/>
			</CustomDrawer>
		)
	}
}

export default DNSDesignateWrapper