export const resourcesSchema = [
  {

    keyword: 'instances',
    label: 'instances',
  },
  {
    keyword: 'volumes',
    label: 'volumes',
  },
  {
    keyword: 'private-images',
    label: 'privateImages',
  },
  {
    keyword: 'snapshots',
    label: 'snapshots',
  },
  {
    keyword: 'backups',
    label: 'backups',
  },
  {
    keyword: 'public-ip-addresses',
    label: 'floatingIPAddressesLabel',
  },
  {
    keyword: 'routers',
    label: 'routers',
  },
  {
    keyword: 'vpns',
    label: 'vpnsLabel',
  },
  {
    keyword: 'firewall-rules',
    label: 'firewallRulesLabel',
  },
]

export const resourceUsageSchema = [
  {
    keyword: 'cpu_cores',
    label: 'cpuCoresQuotaLabel',
    category: 'compute',
  },
  {
    keyword: 'ram',
    label: 'ramQuotaLabel',
    category: 'compute',
  },
  {
    keyword: 'server_instances',
    label: 'instancesQuotaLabel',
    category: 'compute',
  },
  {
    keyword: 'total_allowed_keypairs',
    label: 'sshQuotaLabel',
    category: 'compute',
  },
  {
    keyword: 'volumes',
    label: 'volumesQuotaLabel',
    category: 'storage',
  },
  {
    keyword: 'gigabytes',
    label: 'volumesCapacityQuotaLabel',
    category: 'storage',
  },
  {
    keyword: 'snapshots',
    label: 'snapshotsQuotaLabel',
    category: 'storage',
  },
  {
    keyword: 'backups',
    label: 'backupsQuotaLabel',
    category: 'storage',
  },
  {
    keyword: 'backup_gigabytes',
    label: 'backupsCapacityQuotaLabel',
    category: 'storage',
  },
  {
    keyword: 'network',
    label: 'networksQuotaLabel',
    category: 'networking',
  },
  {
    keyword: 'subnet',
    label: 'subnetsQuotaLabel',
    category: 'networking',
  },
  {
    keyword: 'port',
    label: 'portsQuotaLabel',
    category: 'networking',
  },
  {
    keyword: 'trunk',
    label: 'trunksQuotaLabel',
    category: 'networking',
  },
  {
    keyword: 'router',
    label: 'routersQuotaLabel',
    category: 'networking',
  },
  {
    keyword: 'floatingip',
    label: 'floatingIpsQuotaLabel',
    category: 'networking',
  },
  {
    keyword: 'security_group',
    label: 'securityGroupsQuotaLabel',
    category: 'security',
  },
  {
    keyword: 'security_group_rule',
    label: 'securityGroupRulesQuotaLabel',
    category: 'security',
  },
  {
    keyword: 'firewall_group',
    label: 'firewalGroupsQuotaLabel',
    category: 'security',
  },
  {
    keyword: 'firewall_policy',
    label: 'firewallPoliciesQuotaLabel',
    category: 'security',
  },
  {
    keyword: 'firewall_rule',
    label: 'firewallRulesQuotaLabel',
    category: 'security',
  },
  {
    keyword: 'ikepolicy',
    label: 'ikePoliciesQuotaLabel',
    category: 'vpn',
  },
  {
    keyword: 'ipsecpolicy',
    label: 'ipsecPoliciesQuotaLabel',
    category: 'vpn',
  },
  {
    keyword: 'endpoint_group',
    label: 'vpnEndpointGroupsQuotaLabel',
    category: 'vpn',
  },
  {
    keyword: 'vpnservice',
    label: 'vpnServicesQuotaLabel',
    category: 'vpn',
  },
  {
    keyword: 'ipsec_site_connection',
    label: 'vpnConnectionsQuotaLabel',
    category: 'vpn',
  },
  {
    keyword: 'api_export_size',
    label: 'apiExportSizeQuotaLabel',
    category: 'dns',
  },
  {
    keyword: 'recordset_records',
    label: 'recordsetRecordsQuotaLabel',
    category: 'dns',
  },
  {
    keyword: 'zone_records',
    label: 'zoneRecordsQuotaLabel',
    category: 'dns',
  },
  {
    keyword: 'zone_recordsets',
    label: 'ZoneRecordsetsQuotaLabel',
    category: 'dns',
  },
  {
    keyword: 'zones',
    label: 'zonesQuotaLabel',
    category: 'dns',
  },
]

export const resourceUsageCategoriesSchema = [
  {
    keyword: 'compute',
    label: 'drawerServiceCompute',
  },
  {
    keyword: 'storage',
    label: 'drawerServiceStorage',
  },
  {
    keyword: 'networking',
    label: 'networkingLabel',
  },
  {
    keyword: 'security',
    label: 'submenuSecurity',
  },
  {
    keyword: 'vpn',
    label: 'vpnLabel',
  },
  {
    keyword: 'dns',
    label: 'drawerServiceDns',
  },
]