export const openStackServices = {
    computeService: 'compute_nova',
    volumeService: 'volumes_cinder',
    imageService: 'images_glance',
    networkService: 'networks_neutron',
    identityService: 'identity_keystone',
    backupService: 'backups_dr',
    dnsService: 'dns_designate',
    loadbalancerService: 'loadbalancers_octavia'
};

export const openStackServiceLabels = {
    compute_nova: 'Compute (Nova)',
    volumes_cinder: 'Block Storage (Cinder)',
    images_glance: 'Images (Glance)',
    networks_neutron: 'Networks (Neutron)',
    identity_keystone: 'Identity (Keystone)',
    dns_designate: 'DNS (Designate)',
    loadbalancers_octavia: 'Loadbalancer (Octavia)'
};

export const openStackResourceLabels = {
    instance: 'Flavor',
    flavor: 'Flavor',
    volume: 'Volume Type',
    volume_type: 'Volume Type',
    snapshot: 'Snapshot',
    backup: 'Backup',
    image: 'Image',
    router: 'Router',
    floating_ip: 'Floating IP Address',
    fwaas_rule: "Firewall Rule Range",
    vpn_ike: "VPN IKE Encryption",
    vpn_ipsec: "VPN IPsec Encryption",
    vpn: "VPN",
};

export const openStackServiceVersions = {
    compute_nova: ["v2.1"],
    volumes_cinder: ["v3"],
    images_glance: ["v2"],
    networks_neutron: ["v2.0"],
    identity_keystone: ["v3"],
    dns_designate: ["v2"],
    loadbalancers_octavia: ["v2.1"]
};

export const identityKeystonConstants = {
    supported_api_versions: ["v3"],
    authTokenUrl: 'authTokenUrl',
    authTokensApiUrls: 'authTokensApiUrls',
    usersUrl: 'usersUrl',
    roleAssignmentsUrl: 'roleAssignmentsUrl',
    rolesApiUrls: 'rolesApiUrls',
    rolesUrl: 'rolesUrl',
    usersApiUrls: 'usersApiUrls',
    domainsUrl: 'domainsUrl',
    identityDomainsApiUrls: "identityDomainsApiUrls",
    regionsUrl: 'regionsUrl',
    identityRegionsApiUrls: "identityRegionsApiUrls",
    groupsUrl: 'groupsUrl',
    identityGroupsApiUrls: "identityGroupsApiUrls",
    projectsUrl: "projectsUrl",
    identityProjectsApiUrls: "identityProjectsApiUrls",
    servicesUrl: "servicesUrl",
    endpointsUrl: "endpointsUrl",
    serviceCatalogApiUrls: 'serviceCatalogApiUrls'
}

export const computeNovaConstants = {
    supported_api_versions: ["v2.1"],
    serversUrl: "serversUrl",
    serversApiUrls: "serversApiUrls",
    serverGroupsUrl: "serverGroupsUrl",
    usageUrl: "usageUrl",
    usageApiUrls: "usageApiUrls",
    flavorsUrl: "flavorsUrl",
    flavorsApiUrls: "flavorsApiUrls",
    keyPairsUrl: "keyPairsUrl",
    keyPairsApiUrls: "keyPairsApiUrls",
    limitsUrl: "limitsUrl",
    computeLimitsApiUrls: "computeLimitsApiUrls",
    quotaSetsUrl: "quotaSetsUrl",
    aggregatesUrl: "aggregatesUrl",
    infrastructureApiUrls: "infrastructureApiUrls",
    availabilityZonesUrl: "availabilityZonesUrl",
    hypervisorsUrl: "hypervisorsUrl",
    computeServicesUrl: "computeServicesUrl",
    apiVersionHeaderOld: "X-OpenStack-Nova-API-Version",
    apiVersionHeaderNew: "OpenStack-API-Version"
}

export const blockStorageCinderConstants = {
    supported_api_versions: ["v3"],
    apiVersionHeaderNew: "OpenStack-API-Version",
    volumesUrl: "volumesUrl",
    volumesApiUrls: "volumesApiUrls",
    attachmentsUrl: "attachmentsUrl",
    volumeTypesUrl: "volumeTypesUrl",
    defaultVolumeTypesUrl: "defaultVolumeTypesUrl",
    volumeTypesApiUrls: "volumeTypesApiUrls",
    snapshotsUrl: "snapshotsUrl",
    snapshotsApiUrls: "snapshotsApiUrls",
    backupsUrl: "backupsUrl",
    backupsApiUrls: "backupsApiUrls",
    clustersUrl: "clustersUrl",
    clustersApiUrls: "clustersApiUrls",
    groupsUrl: "groupsUrl",
    groupsApiUrls: "groupsApiUrls",
    groupSnapshotsUrl: "groupSnapshotsUrl",
    groupTypesUrl: "groupTypesUrl",
    limitsUrl: "limitsUrl",
    limitsApiUrls: "limitsApiUrls",
    qosSpecsUrl: "qosSpecsUrl",
    qosSpecsApiUrls: "qosSpecsApiUrls",
    quotasUrl: "quotasUrl",
    quotasApiUrls: "quotasApiUrls",
    volumeTransfersUrl: "volumeTransfersUrl",
    volumeProjectIdField: "os-vol-tenant-attr:tenant_id",
    snapshotProjectIdField: "os-extended-snapshot-attributes:project_id",
    backupProjectIdField: "os-backup-project-attr:project_id"
}

export const imagesGlanceConstants = {
    supported_api_versions: ["v2"],
    imagesUrl: "imagesUrl",
    imagesApiUrls: "imagesApiUrls"
}

export const networkNeutronConstants = {
    supported_api_versions: ["v2.0"],
    subnetsUrl: "subnetsUrl",
    subnetsApiUrls: "subnetsApiUrls",
    networksUrl: "networksUrl",
    networksApiUrls: "networksApiUrls",
    portsUrl: "portsUrl",
    portsApiUrls: "portsApiUrls",
    secGroupsUrl: "secGroupsUrl",
    secGroupsApiUrls: "secGroupsApiUrls",
    l2networksApiUrls: "l2networksApiUrls",
    segmentsUrl: "segmentsUrl",
    trunksUrl: "trunksUrl",
    floatingIPsUrl: "floatingIPsUrl",
    floatingIPPoolsUrl: "floatingIPPoolsUrl",
    routersUrl: "routersUrl",
    virtualIPsUrl: "virtualIPsUrl",
    securityGroupsUrl: "securityGroupsUrl",
    securityGroupRulesUrl: "securityGroupRulesUrl",
    defaultSecGroupRulesUrl: "defaultSecGroupRulesUrl",
    addressGroupsUrl: "addressGroupsUrl",
    securityGroupsApiUrls: "securityGroupsApiUrls",
    computeNovaOwner: "compute:nova",
    routerInterfaceOwner: "network:router_interface",
    firewallGroupsUrl: "firewallGroupsUrl",
    firewallPoliciesUrl: "firewallPoliciesUrl",
    firewallRulesUrl: "firewallRulesUrl",
    fwaasGroupsApiUrls: "fwaasGroupsApiUrls",
    vpnApiUrls: "vpnApiUrls",
    vpnIKEPoliciesUrl: "vpnIKEPoliciesUrl",
    vpnIPsecPoliciesUrl: "vpnIPsecPoliciesUrl",
    vpnSiteConnectionsUrl: "vpnSiteConnectionsUrl",
    vpnEndpointGroupsUrl: "vpnEndpointGroupsUrl",
    vpnServicesUrl: "vpnServicesUrl",
    defaultVPNSiteAuthMode: "psk",
    defaultVPNSiteRouteMode: "static",
    limitsUrl: "limitsUrl",
    limitsApiUrls: "limitsApiUrls"
}

export const dnsDesignateConstants = {
    supported_api_versions: ["v2"],
    apiVersionHeaderNew: "OpenStack-API-Version",
    dnsApiUrls: "dnsApiUrls",
    zonesUrl: "zonesUrl",
    poolsUrl: "poolsUrl",
    tldsUrl: "tldsUrl",
    tsigkeysUrl: "tsigkeysUrl",
    blacklistsUrl: "blacklistsUrl",
    quotasUrl: "quotasUrl",
    serviceStatusesUrl: "serviceStatusesUrl",
    reverseFloatingIPsUrl: "reverseFloatingIPsUrl"
}

export const backupsDRConstants = {
    supported_api_versions: ["v2"]
}