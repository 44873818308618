import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Skeleton from '@mui/material/Skeleton'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CustomPopover from '../../../_common/CustomPopover'
import constants from '../../../../config/constants'
import useWindowDimensions from '../../../_common/WindowDimensions'
import { billingRequest } from '../../../../_network/openstack_request'
import { billingUrls } from '../../../../_network/apiUrls'
import { getCurrentMonthFirstDate, getCurrentMonthLastDate, addTimeToDate } from '../../helpers/dates'
import BillingTableRow from './BillingTableRow'

const BillingTable = (props) => {
  const theme = useTheme()
  const { isDnsService, handleClickResource } = props
  const { width } = useWindowDimensions()

  const [serviceChargesData, setServiceChargesData] = useState([])
  const [serviceCharges, setServiceCharges] = useState([])
  const [startDate, setStartDate] = useState(getCurrentMonthFirstDate())
  const [endDate, setEndDate] = useState(getCurrentMonthLastDate())
  const [isLoading, setIsLoading] = useState(true)
  const [reload, setReload] = useState(1)

  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const accessToken = useSelector(state => state.profile.access_token)
  const xAuthToken = useSelector(state => state.profile.x_auth_token)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)
  const drawerOpened = useSelector(state => state.drawer.drawerOpened)
  const defaultTexts = useSelector(state => state.texts.langTexts)

  const containerStyle = {
    paddingTop: '10px',
    marginTop: '10px',
    background: theme.palette.customWhite,
    borderTop: `${theme.palette.customGrayDark} 1px solid`,
  }

  const handleChangeStartDate = (value) => {
    setStartDate(value)
  }

  const handleChangeEndDate = (value) => {
    setEndDate(value)
  }

  const handleFilterReset = () => {
    setReload((prev) => prev + 1)
    setStartDate(getCurrentMonthFirstDate())
    setEndDate(getCurrentMonthLastDate())
  }

  useEffect(() => {
    const fetchServiceChargesData = async () => {
      setIsLoading(true)

      let filterArray = []

      if (startDate) {
        const startDateString = addTimeToDate(startDate, 'start')
        filterArray.push(`start_time=${encodeURIComponent(startDateString)}`)
      }

      if (endDate) {
        const endDateString = addTimeToDate(endDate, 'end')
        filterArray.push(`end_time=${encodeURIComponent(endDateString)}`)
      }

      let filterString = filterArray.join('&')
      if (filterString) {
        filterString = '/?' + filterString
      }

      const url = constants.billing_url + '/' + billingUrls.getServiceCharges + '/' + defaultAdminProject + filterString

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        xAuthToken,
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
        setServiceChargesData(response.data.filter(item => item.service_name !== 'dns_designate' || isDnsService))
      } else {
        setServiceChargesData([])
      }

      setIsLoading(false)
    }

    fetchServiceChargesData()
  }, [accessToken, xAuthToken, clientAccountID, defaultAdminProject, startDate, endDate, reload, isDnsService])

  useEffect(() => {
    let service_charges = [...serviceChargesData]

    serviceChargesData.forEach((serv, servIndex) => {
      let serv_sum = 0

      if (serv.regions) {
        serv.regions.forEach((reg, regIndex) => {
          let reg_sum = 0

          if (reg.service_resources) {
            reg.service_resources.forEach((sres, sresIndex) => {
              let sres_sum = 0
              
              if (sres.resources) {
                sres.resources.forEach(res => {
                  if (res.data && res.data.total_cost) {
                    sres_sum += parseFloat(res.data.total_cost)
                  }
                })
              } else if (sres.data && sres.data.total_cost) {
                sres_sum += parseFloat(sres.data.total_cost)
              }

              service_charges[servIndex].regions[regIndex].service_resources[sresIndex].sum = parseFloat(sres_sum)
              
              reg_sum += parseFloat(sres_sum)
            })
          }

          service_charges[servIndex].regions[regIndex].sum = parseFloat(reg_sum)
          serv_sum += parseFloat(reg_sum)
        })
      }

      service_charges[servIndex].sum = parseFloat(serv_sum)
    })

    setServiceCharges(service_charges)
  }, [serviceChargesData])

  const getFilter = () => {
    return (
      <Stack
        direction={ width >= 900 ? 'row' : 'column' }
        justifyContent="flex-start" 
        alignItems={ width >= 900 ? 'center' : 'flex-start' }
        sx={{
          padding: width >= 900 ? '0px 20px 20px 20px' : '0px',
          width: "100%",
        }}
      >
        <Box>
          <DatePicker
            label=''
            value={new Date(startDate)}
            onChange={e => handleChangeStartDate(new Date(e))}
            maxDate={new Date(endDate)}
            slotProps={{ textField: { size: 'small' } }}
            sx={{ width: '160px' }}
          />
        </Box>
        <Box sx={{ margin: '0px 10px' }}>{ width >= 900 ? '-' : <Box sx={{ width: '1px', height: '10px' }}></Box> }</Box>
        <Box>
          <DatePicker
            label=''
            value={new Date(endDate)}
            onChange={e => handleChangeEndDate(new Date(e))}
            minDate={new Date(startDate)}
            slotProps={{ textField: { size: 'small' } }}
            sx={{ width: '160px' }}
          />
        </Box>
        <IconButton
          sx={{
            color: theme.palette.primary.main,
            marginLeft: width >= 900 ? '10px' : '0px',
            marginTop: width >= 900 ? '0px' : '10px',
          }}
          onClick={handleFilterReset}
        >
          <RefreshIcon fontSize='large' />
        </IconButton>
      </Stack>
    )
  }
  
  return (
    <Box sx={containerStyle}>
      {
        width >= 900 ?
        <Box sx={{ paddingBottom: '10px' }}>
          {getFilter()}
        </Box> :
        <Box sx={{ paddingLeft: '10px' }}>
          <CustomPopover
            type='filterlist'
            horizontalOrigin='left'
          >
            {getFilter()}
          </CustomPopover>
        </Box>          
      }

      <Box
        sx={{
          width: drawerOpened ? `${width - 270 - 12}px` : `${width - 65 - 12}px`,
          overflowX: 'auto',
        }}
      >
        {
          isLoading ?
          <Stack
            spacing={1}
            direction='row'
            justifyContent='space-between'
            sx={{ padding: '20px', width: '100%' }}
          >
            <Box sx={{ width: '25%' }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '25%' }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '25%' }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>            
          </Stack> :
          <TableContainer component={Paper} sx={{ borderRadius: '0px' }}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '30px' }} />
                  <TableCell>{defaultTexts.descriptionFormFieldLabel}</TableCell>
                  <TableCell sx={{ width: '30%' }}>{defaultTexts.usageQuantityLabel}</TableCell>
                  <TableCell align='right' sx={{ width: '20%' }}>{defaultTexts.amountLabel}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  serviceCharges.map((item, index) => (
                    <BillingTableRow
                      key={index}
                      row={item}
                      level={1}
                      handleClickResource={handleClickResource}
                    />
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        }        
      </Box>

    </Box>
  )
}

export default BillingTable