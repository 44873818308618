import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart'
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge'
import { pink, purple, cyan, teal, lightGreen, lime, orange, deepOrange, brown } from '@mui/material/colors'
import CustomSelectField from '../../../_common/_form_fields/CustomSelectField'
import CustomTextField from '../../../_common/_form_fields/CustomTextField'
import CustomCheckboxField from '../../../_common/_form_fields/CustomCheckboxField'
import useWindowDimensions from '../../../_common/WindowDimensions'
import constants from '../../../../config/constants'
import { billingRequest } from '../../../../_network/openstack_request'
import { billingUrls } from '../../../../_network/apiUrls'
import { calculatorServicesSchema } from '../../helpers/billingHelpers'
import { getCurrentMonthLastDay } from '../../helpers/dates'

const PlanningCalculator = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()
  
  const defaultTexts = useSelector(state => state.texts.langTexts)
  const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject)
  const accessToken = useSelector(state => state.profile.access_token)
  const clientAccountID = useSelector(state => state.settings.clientAccountID)

  const [calculatorServices, setCalculatorServices] = useState([])
  const [accountPricesData, setAccountPricesData] = useState([])
  const [accountPrices, setAccountPrices] = useState([])
  const [regionList, setRegionList] = useState([])
  const [region, setRegion] = useState('')
  const [currentTab, setCurrentTab] = useState(calculatorServicesSchema.length > 0 ? calculatorServicesSchema[0].value : '')
  const [selectedResources, setSelectedResources] = useState([])
  const [resourcesChartData, setResourcesChartData] = useState([])
  const [servicesChartData, setServicesChartData] = useState([])
  const [currencyName, setCurrencyName] = useState('')
  const [configs, setConfigs] = useState('')
  const [calculatorDisabled, setCalculatorDisabled] = useState(true)
  
  const [flavorsData, setFlavorsData] = useState([])
  const [flavorsList, setFlavorsList] = useState([])
  const [selectedFlavor, setSelectedFlavor] = useState('')
  const [flavorCount, setFlavorCount] = useState(1)
  const [selectedFlavors, setSelectedFlavors] = useState([])

  const [volumesData, setVolumesData] = useState([])
  const [volumesList, setVolumesList] = useState([])
  const [selectedVolume, setSelectedVolume] = useState('')
  const [volumeSize, setVolumeSize] = useState(1)
  const [volumeCount, setVolumeCount] = useState(1)
  const [selectedVolumes, setSelectedVolumes] = useState([])

  const [backupsData, setBackupsData] = useState([])
  const [backupsChecked, setBackupsChecked] = useState(false)
  const [backupSize, setBackupSize] = useState(1)
  const [backupCount, setBackupCount] = useState(1)

  const [snapshotsData, setSnapshotsData] = useState([])
  const [snapshotsChecked, setSnapshotsChecked] = useState(false)
  const [snapshotSize, setSnapshotSize] = useState(1)
  const [snapshotCount, setSnapshotCount] = useState(1)

  const [ikePolicyData, setIkePolicyData] = useState([])
  const [ikePolicyList, setIkePolicyList] = useState([])
  const [selectedIkePolicy, setSelectedIkePolicy] = useState('')
  const [ipsecPolicyData, setIpsecPolicyData] = useState([])
  const [ipsecPolicyList, setIpsecPolicyList] = useState([])
  const [selectedIpsecPolicy, setSelectedIpsecPolicy] = useState('')
  const [selectedVpns, setSelectedVpns] = useState([])

  const [routersData, setRoutersData] = useState([])
  const [routersChecked, setRoutersChecked] = useState(false)
  const [routerCount, setRouterCount] = useState(1)

  const [floatingIpData, setFloatingIpData] = useState([])
  const [floatingIpChecked, setFloatingIpChecked] = useState(false)
  const [floatingIpCount, setFloatingIpCount] = useState(1)

  const [firewallRulesData, setFirewallRulesData] = useState([])
  const [firewallRulesList, setFirewallRulesList] = useState([])
  const [selectedFirewallRule, setSelectedFirewallRule] = useState('')

  const [imagesData, setImagesData] = useState([])
  const [imagesChecked, setImagesChecked] = useState(false)
  const [imageSize, setImageSize] = useState(1)
  const [imageCount, setImageCount] = useState(1)

  const [zoneData, setZoneData] = useState([])
  const [zoneChecked, setZoneChecked] = useState(false)
  const [zoneCount, setZoneCount] = useState(1)

  const labelStyle = {
    color: theme.palette.secondary.main,
    fontSize: '18px',
  }

  const pieChartLabelStyle = {
    fill: 'white',
    fontSize: '14px',
  }

  const totalAmountStyle = {
    color: theme.palette.success.main,
    fontSize: '20px',
    fontWeight: '500',
  }

  const monthlyLabelStyle = {
    color: theme.palette.primary.main,
  }

  const resourceLabelStyle = {
    color: theme.palette.primary.main,
  }

  const resourcePriceStyle = {
    color: theme.palette.secondary.main,
    fontSize: '14px',
    padding: '3px 0px 5px 0px',
  }

  const resourceCountStyle = {
    color: theme.palette.secondary.main,
  }

  const resourceDescriptionStyle = {
    color: theme.palette.customGrayDark,
  }

  const calculateResourcePrice = (amount) => {
    let result = parseFloat(amount)

    if (configs.use_vat_tax && !configs.price_includes_vat) {
      result = result + (result * (configs.vat / 100))
    }

    return result
  }

  const getPriceFromFlavorList = (id) => {
    const find_flavor = flavorsList.find(item => item.value === id)
    return find_flavor ? calculateResourcePrice(find_flavor.price) : 0
  }

  const getPriceFromVolumeList = (id) => {
    const find_volume = volumesList.find(item => item.value === id)
    return find_volume ? calculateResourcePrice(find_volume.price) : 0
  }

  const getPriceFromIkePolicyList = (id) => {
    const find_ike_policy = ikePolicyList.find(item => item.value === id)
    return find_ike_policy ? calculateResourcePrice(find_ike_policy.price) : 0
  }

  const getPriceFromIpsecPolicyList = (id) => {
    const find_ipsec_policy = ipsecPolicyList.find(item => item.value === id)
    return find_ipsec_policy ? calculateResourcePrice(find_ipsec_policy.price) : 0
  }

  const getLabelFromFlavorList = (id) => {
    const find_flavor = flavorsList.find(item => item.value === id)
    return find_flavor ? find_flavor.keyword : ''
  }

  const getLabelFromVolumeList = (id) => {
    const find_volume = volumesList.find(item => item.value === id)
    return find_volume ? find_volume.keyword : ''
  }

  const getLabelFromIkePolicyList = (id) => {
    const find_ike_policy = ikePolicyList.find(item => item.value === id)
    return find_ike_policy ? find_ike_policy.keyword : ''
  }

  const getLabelFromIpsecPolicyList = (id) => {
    const find_ipsec_policy = ipsecPolicyList.find(item => item.value === id)
    return find_ipsec_policy ? find_ipsec_policy.keyword : ''
  }
  
  const handleChangeTab = (event, v) => {
    setCurrentTab(v)
  }

  const handleAddFlavor = () => {
    let updated_selected_resources = []
    let add = true

    selectedResources.forEach(item => {
      if (item.id === selectedFlavor) {
        updated_selected_resources.push({ ...item, count: item.count + flavorCount })
        add = false
      } else {
        updated_selected_resources.push(item)
      }
    })

    if (add && selectedFlavor) {
      updated_selected_resources.push({
        id: selectedFlavor,
        resource_name: 'flavor',
        count: flavorCount,
        size: 1,
        price: getPriceFromFlavorList(selectedFlavor),
      })
    }

    setSelectedResources(updated_selected_resources)
  }

  const handleAddVolume = () => {
    let updated_selected_resources = []
    let add = true

    selectedResources.forEach(item => {
      if (item.id === selectedVolume) {
        updated_selected_resources.push({ ...item, count: item.count + volumeCount, size: item.size + volumeSize })
        add = false
      } else {
        updated_selected_resources.push(item)
      }
    })

    if (add && selectedVolume) {
      updated_selected_resources.push({
        id: selectedVolume,
        resource_name: 'volume',
        count: volumeCount,
        size: volumeSize,
        price: getPriceFromVolumeList(selectedVolume),
      })
    }

    setSelectedResources(updated_selected_resources)
  }

  const handleAddBackup = () => {
    let updated_selected_resources = [...selectedResources]

    if (backupsData.length > 0) {
      updated_selected_resources.push({
        id: backupsData[0].id,
        resource_name: 'backup',
        count: backupCount,
        size: backupSize,
        price: calculateResourcePrice(backupsData[0].price),
      })
    }
    
    setSelectedResources(updated_selected_resources)
  }

  const handleAddSnapshot = () => {
    let updated_selected_resources = [...selectedResources]

    if (snapshotsData.length > 0) {
      updated_selected_resources.push({
        id: snapshotsData[0].id,
        resource_name: 'snapshot',
        count: snapshotCount,
        size: snapshotSize,
        price: calculateResourcePrice(snapshotsData[0].price),
      })
    }
    
    setSelectedResources(updated_selected_resources)
  }

  const handleAddVpn = () => {
    let updated_selected_resources = [...selectedResources]

    if (selectedIkePolicy && selectedIkePolicy) {
      updated_selected_resources.push({
        ike_id: selectedIkePolicy,
        ipsec_id: selectedIpsecPolicy,
        resource_name: 'vpn',
        count: 1,
        size: 1,
        price: getPriceFromIkePolicyList(selectedIkePolicy) + getPriceFromIpsecPolicyList(selectedIpsecPolicy),
      })
    }

    setSelectedResources(updated_selected_resources)
  }

  const handleAddRouter = () => {
    let updated_selected_resources = [...selectedResources]

    if (routersData.length > 0) {
      updated_selected_resources.push({
        id: routersData[0].id,
        resource_name: 'router',
        count: routerCount,
        size: 1,
        price: calculateResourcePrice(routersData[0].price),
      })
    }
    
    setSelectedResources(updated_selected_resources)
  }

  const handleAddFloatingIp = () => {
    let updated_selected_resources = [...selectedResources]

    if (floatingIpData.length > 0) {
      updated_selected_resources.push({
        id: floatingIpData[0].id,
        resource_name: 'floating-ip',
        count: floatingIpCount,
        size: 1,
        price: calculateResourcePrice(floatingIpData[0].price),
      })
    }
    
    setSelectedResources(updated_selected_resources)
  }

  const handleAddFirewallRules = (id) => {
    let updated_selected_resources = [...selectedResources].filter(item => item.resource_name !== 'fwaas-rule')

    const firewall_rule = firewallRulesList.find(item => item.value === id)

    if (firewall_rule) {
      updated_selected_resources.push({
        id: id,
        resource_name: 'fwaas-rule',
        count: 1,
        size: 1,
        price: calculateResourcePrice(firewall_rule.price),
      })
    }
    
    setSelectedResources(updated_selected_resources)
  }

  const handleAddImage = () => {
    let updated_selected_resources = [...selectedResources]

    if (imagesData.length > 0) {
      updated_selected_resources.push({
        id: imagesData[0].id,
        resource_name: 'image',
        count: imageCount,
        size: imageSize,
        price: calculateResourcePrice(imagesData[0].price),
      })
    }
    
    setSelectedResources(updated_selected_resources)
  }

  const handleAddZone = () => {
    let updated_selected_resources = [...selectedResources]

    if (zoneData.length > 0) {
      updated_selected_resources.push({
        id: zoneData[0].id,
        resource_name: 'zone',
        count: zoneCount,
        size: 1,
        price: calculateResourcePrice(zoneData[0].price),
      })
    }
    
    setSelectedResources(updated_selected_resources)
  }

  const handleRemoveResource = (id) => {
    const updated_selected_resources = selectedResources.filter(item => item.id !== id)
    setSelectedResources(updated_selected_resources)
  }

  const handleRemoveVpn = ({ ike_id, ipsec_id }) => {
    const updated_selected_resources = selectedResources.filter(item => (item.ike_id !== ike_id || item.ipsec_id !== ipsec_id))
    setSelectedResources(updated_selected_resources)
  }

  const handleChangeBackupChecked = (event) => {
    setBackupsChecked(event.target.checked)
    if (event.target.checked) {
      handleAddBackup()
    } else if (backupsData.length > 0) {
      handleRemoveResource(backupsData[0].id)
      setBackupSize(1)
      setBackupCount(1)
    }
  }

  const handleChangeSnapshotChecked = (event) => {
    setSnapshotsChecked(event.target.checked)
    if (event.target.checked) {
      handleAddSnapshot()
    } else if (snapshotsData.length > 0) {
      handleRemoveResource(snapshotsData[0].id)
      setSnapshotSize(1)
      setSnapshotCount(1)
    }
  }

  const handleChangeRouterChecked = (event) => {
    setRoutersChecked(event.target.checked)
    if (event.target.checked) {
      handleAddRouter()
    } else if (routersData.length > 0) {
      handleRemoveResource(routersData[0].id)
      setRouterCount(1)
    }
  }

  const handleChangeFloatingIpChecked = (event) => {
    setFloatingIpChecked(event.target.checked)
    if (event.target.checked) {
      handleAddFloatingIp()
    } else if (floatingIpData.length > 0) {
      handleRemoveResource(floatingIpData[0].id)
      setFloatingIpCount(1)
    }
  }

  const handleChangeImageChecked = (event) => {
    setImagesChecked(event.target.checked)
    if (event.target.checked) {
      handleAddImage()
    } else if (imagesData.length > 0) {
      handleRemoveResource(imagesData[0].id)
      setImageSize(1)
      setImageCount(1)
    }
  }

  const handleChangeZoneChecked = (event) => {
    setZoneChecked(event.target.checked)
    if (event.target.checked) {
      handleAddZone()
    } else if (zoneData.length > 0) {
      handleRemoveResource(zoneData[0].id)
      setZoneCount(1)
    }
  }

  const handleChangeBackupSize = (event) => {
    const new_size = parseInt(event.target.value)

    setBackupSize(new_size)

    if (backupsData.length > 0) {
      let updated_selected_resources = []

      selectedResources.forEach(item => {
        if (item.id === backupsData[0].id) {
          updated_selected_resources.push({
            ...item,
            size: new_size,
          })
        } else {
          updated_selected_resources.push(item)
        }
      })

      setSelectedResources(updated_selected_resources)
    }    
  }

  const handleChangeBackupCount = (event) => {
    const new_count = parseInt(event.target.value)

    setBackupCount(new_count)

    if (backupsData.length > 0) {
      let updated_selected_resources = []

      selectedResources.forEach(item => {
        if (item.id === backupsData[0].id) {
          updated_selected_resources.push({
            ...item,
            count: new_count,
          })
        } else {
          updated_selected_resources.push(item)
        }
      })

      setSelectedResources(updated_selected_resources)
    }    
  }

  const handleChangeSnapshotSize = (event) => {
    const new_size = parseInt(event.target.value)

    setSnapshotSize(new_size)

    if (snapshotsData.length > 0) {
      let updated_selected_resources = []

      selectedResources.forEach(item => {
        if (item.id === snapshotsData[0].id) {
          updated_selected_resources.push({
            ...item,
            size: new_size,
          })
        } else {
          updated_selected_resources.push(item)
        }
      })

      setSelectedResources(updated_selected_resources)
    }    
  }

  const handleChangeSnapshotCount = (event) => {
    const new_count = parseInt(event.target.value)

    setSnapshotCount(new_count)

    if (snapshotsData.length > 0) {
      let updated_selected_resources = []

      selectedResources.forEach(item => {
        if (item.id === snapshotsData[0].id) {
          updated_selected_resources.push({
            ...item,
            count: new_count,
          })
        } else {
          updated_selected_resources.push(item)
        }
      })

      setSelectedResources(updated_selected_resources)
    }    
  }

  const handleChangeRouterCount = (event) => {
    const new_count = parseInt(event.target.value)

    setRouterCount(new_count)

    if (routersData.length > 0) {
      let updated_selected_resources = []

      selectedResources.forEach(item => {
        if (item.id === routersData[0].id) {
          updated_selected_resources.push({
            ...item,
            count: new_count,
          })
        } else {
          updated_selected_resources.push(item)
        }
      })

      setSelectedResources(updated_selected_resources)
    }    
  }

  const handleChangeFloatingIpCount = (event) => {
    const new_count = parseInt(event.target.value)

    setFloatingIpCount(new_count)

    if (floatingIpData.length > 0) {
      let updated_selected_resources = []

      selectedResources.forEach(item => {
        if (item.id === floatingIpData[0].id) {
          updated_selected_resources.push({
            ...item,
            count: new_count,
          })
        } else {
          updated_selected_resources.push(item)
        }
      })

      setSelectedResources(updated_selected_resources)
    }    
  }

  const handleChangeFirewallRule = (value) => {
    setSelectedFirewallRule(value)

    if (value) {
      handleAddFirewallRules(value)
    } else {
      const updated_selected_resources = [...selectedResources].filter(item => item.resource_name !== 'fwaas-rule')
      setSelectedResources(updated_selected_resources)
    }
  }

  const handleChangeImageSize = (event) => {
    const new_size = parseInt(event.target.value)

    setImageSize(new_size)

    if (imagesData.length > 0) {
      let updated_selected_resources = []

      selectedResources.forEach(item => {
        if (item.id === imagesData[0].id) {
          updated_selected_resources.push({
            ...item,
            size: new_size,
          })
        } else {
          updated_selected_resources.push(item)
        }
      })

      setSelectedResources(updated_selected_resources)
    }    
  }

  const handleChangeImageCount = (event) => {
    const new_count = parseInt(event.target.value)

    setImageCount(new_count)

    if (imagesData.length > 0) {
      let updated_selected_resources = []

      selectedResources.forEach(item => {
        if (item.id === imagesData[0].id) {
          updated_selected_resources.push({
            ...item,
            count: new_count,
          })
        } else {
          updated_selected_resources.push(item)
        }
      })

      setSelectedResources(updated_selected_resources)
    }    
  }

  const handleChangeZoneCount = (event) => {
    const new_count = parseInt(event.target.value)

    setZoneCount(new_count)

    if (zoneData.length > 0) {
      let updated_selected_resources = []

      selectedResources.forEach(item => {
        if (item.id === zoneData[0].id) {
          updated_selected_resources.push({
            ...item,
            count: new_count,
          })
        } else {
          updated_selected_resources.push(item)
        }
      })

      setSelectedResources(updated_selected_resources)
    }    
  }

  const calculateTotalAmount = () => {
    let result = 0
    selectedResources.forEach(item => result += parseFloat(item.price) * item.count * item.size)
    return (result * 24 * getCurrentMonthLastDay()).toFixed(2)
  }

  useEffect(() => {
    const fetchAccountPricesData = async () => {
      const url = constants.billing_url + '/' + billingUrls.getAccountPrices

      const request_data = {
        url,
        method: 'GET',
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data && response.data.length > 0) {
        setAccountPricesData(response.data)
      } else {
        setAccountPricesData([])
      }
    }

    fetchAccountPricesData()
  }, [clientAccountID, defaultAdminProject])
  
  useEffect(() => {
    const fetchAccountConfigsPublicData = async () => {
      const url = constants.billing_url + '/' + billingUrls.getAccountConfigsPublic

      const request_data = {
        url,
        method: 'GET',
        accessToken,
        clientAccountID,
      }

      const response = await billingRequest(request_data)
      
      if (response.status_code && response.status_code < 400 && response.data) {
        const currency_name = (response.data.currency && response.data.currency.name) ? (response.data.currency.name).toUpperCase() : ''
        setCurrencyName(currency_name)
        setConfigs(response.data)
      } else {
        setCurrencyName('')
        setConfigs('')
      }
    }

    fetchAccountConfigsPublicData()
  }, [accessToken, clientAccountID, defaultAdminProject])

  useEffect(() => {
    if (accountPricesData.length > 0) {
      const region_list_grouped = Object.groupBy(accountPricesData, ({region_name}) => region_name)
      const region_keys = region_list_grouped ? Object.keys(region_list_grouped) : []
      let region_list = []
      region_keys.forEach(item => region_list.push({ value: item, keyword: item }))
      setRegionList(region_list)
    } else {
      setRegionList([])
    }
  }, [accountPricesData])

  useEffect(() => {
    if (accountPricesData.length > 0) {
      setAccountPrices(accountPricesData.filter(item => item.region_name === region || item.region_name === null))
    } else {
      setAccountPrices([])
    }
  }, [accountPricesData, region])

  useEffect(() => {
    if (regionList.length > 0 && !region) {
      setCalculatorDisabled(true)
    } else {
      setCalculatorDisabled(false)
    }
  }, [region, regionList])

  useEffect(() => {
    setFlavorsData(accountPrices.filter(item => item.service_name === 'compute_nova' && item.resource_name === 'instance'))
    setVolumesData(accountPrices.filter(item => item.service_name === 'volumes_cinder' && item.resource_name === 'volume'))
    setBackupsData(accountPrices.filter(item => item.service_name === 'volumes_cinder' && item.resource_name === 'backup'))
    setSnapshotsData(accountPrices.filter(item => item.service_name === 'volumes_cinder' && item.resource_name === 'snapshot'))
    setIkePolicyData(accountPrices.filter(item => item.service_name === 'networks_neutron' && item.resource_name === 'vpn_ike'))
    setIpsecPolicyData(accountPrices.filter(item => item.service_name === 'networks_neutron' && item.resource_name === 'vpn_ipsec'))
    setRoutersData(accountPrices.filter(item => item.service_name === 'networks_neutron' && item.resource_name === 'router'))
    setFloatingIpData(accountPrices.filter(item => item.service_name === 'networks_neutron' && item.resource_name === 'floating_ip'))
    setFirewallRulesData(accountPrices.filter(item => item.service_name === 'networks_neutron' && item.resource_name === 'fwaas_rule'))
    setImagesData(accountPrices.filter(item => item.service_name === 'images_glance' && item.resource_name === 'image'))
    setZoneData(accountPrices.filter(item => item.service_name === 'dns_designate' && item.resource_name === 'zone' && item.price > 0))
  }, [accountPrices])
  
  useEffect(() => {
    const convertMBtoGB = (v) => {
      if (v < 1024) {
        return v + ' ' + defaultTexts.mblabel
      } else {
        return Math.round(v / 1024) + ' ' + defaultTexts.gblabel
      }
    }

    let flavors_list = []

    flavorsData.forEach(item => {
      flavors_list.push({
        value: item.id,
        keyword: item.props.name + ' (' + item.props.cpu + ' ' + defaultTexts.cpusLabel + ', ' + convertMBtoGB(item.props.ram) + ' ' + defaultTexts.ramTableHeaderLabel + ', ' + item.props.disk + ' ' + defaultTexts.gblabel + ' ' + defaultTexts.diskTableHeaderLabel + ')',
        price: item.price,
      })
    })

    setFlavorsList(flavors_list)
  }, [flavorsData, defaultTexts])

  useEffect(() => {
    let volumes_list = []

    volumesData.forEach(item => {
      volumes_list.push({
        value: item.id,
        keyword: item.props.name,
        price: item.price,
      })
    })

    setVolumesList(volumes_list)
  }, [volumesData])

  useEffect(() => {
    let ike_policy_list = []

    ikePolicyData.forEach(item => {
      ike_policy_list.push({
        value: item.id,
        keyword: item.resource_id,
        price: item.price,
      })
    })

    setIkePolicyList(ike_policy_list)
  }, [ikePolicyData])

  useEffect(() => {
    let ipsec_policy_list = []

    ipsecPolicyData.forEach(item => {
      ipsec_policy_list.push({
        value: item.id,
        keyword: item.resource_id,
        price: item.price,
      })
    })

    setIpsecPolicyList(ipsec_policy_list)
  }, [ipsecPolicyData])

  useEffect(() => {
    let firewall_rules_list = [{
      value: '',
      keyword: '---',
      price: 0,
    }]

    firewallRulesData.forEach(item => {
      firewall_rules_list.push({
        value: item.id,
        keyword: item.resource_count.length > 1 ? item.resource_count[0] + ' - ' + item.resource_count[1] : '',
        price: item.price,
      })
    })

    setFirewallRulesList(firewall_rules_list)
  }, [firewallRulesData])

  useEffect(() => {
    setSelectedFlavors(selectedResources.filter(item => item.resource_name === 'flavor'))
    setSelectedVolumes(selectedResources.filter(item => item.resource_name === 'volume'))
    setSelectedVpns(selectedResources.filter(item => item.resource_name === 'vpn'))
  }, [selectedResources])

  useEffect(() => {
    if (selectedResources.length > 0) {
      let resources_chart_data = []
      let service_chart_data = []

      let compute_total = 0
      let storage_total = 0
      let network_total = 0
      let images_total = 0
      let dns_total = 0

      let flavor_total = 0
      let volume_total = 0
      let backup_total = 0
      let snapshot_total = 0
      let vpn_total = 0
      let router_total = 0
      let floating_ip_total = 0
      let fwaas_rule_total = 0
      let image_total = 0
      let zone_total = 0

      selectedResources.forEach(item => {
        let chunk_amount = item.price * item.count * item.size * 24 * getCurrentMonthLastDay()

        if (item.resource_name === 'flavor') {
          compute_total += chunk_amount
          flavor_total += chunk_amount
        }

        if (item.resource_name === 'volume' || item.resource_name === 'backup' || item.resource_name === 'snapshot') {
          storage_total += chunk_amount
        }

        if (item.resource_name === 'volume') {
          volume_total += chunk_amount
        }

        if (item.resource_name === 'backup') {
          backup_total += chunk_amount
        }

        if (item.resource_name === 'snapshot') {
          snapshot_total += chunk_amount
        }

        if (item.resource_name === 'vpn' || item.resource_name === 'router' || item.resource_name === 'floating-ip' || item.resource_name === 'fwaas-rule') {
          network_total += chunk_amount
        }

        if (item.resource_name === 'vpn') {
          vpn_total += chunk_amount
        }

        if (item.resource_name === 'router') {
          router_total += chunk_amount
        }

        if (item.resource_name === 'floating-ip') {
          floating_ip_total += chunk_amount
        }

        if (item.resource_name === 'fwaas-rule') {
          fwaas_rule_total += chunk_amount
        }

        if (item.resource_name === 'image') {
          images_total += chunk_amount
          image_total += chunk_amount
        }

        if (item.resource_name === 'zone') {
          zone_total += chunk_amount
          dns_total += chunk_amount
        }
      })

      if (compute_total !== 0) {
        service_chart_data.push({
          value: compute_total.toFixed(2),
          label: calculatorServicesSchema[0].label,
        })
      }
      
      if (storage_total !== 0) {
        service_chart_data.push({
          value: storage_total.toFixed(2),
          label: calculatorServicesSchema[1].label,
        })
      }

      if (network_total !== 0) {
        service_chart_data.push({
          value: network_total.toFixed(2),
          label: calculatorServicesSchema[2].label,
        })
      }

      if (images_total !== 0) {
        service_chart_data.push({
          value: images_total.toFixed(2),
          label: calculatorServicesSchema[3].label,
        })
      }

      if (dns_total !== 0) {
        service_chart_data.push({
          value: dns_total.toFixed(2),
          label: calculatorServicesSchema[4].label,
        })
      }

      if (flavor_total !== 0) {
        resources_chart_data.push({
          value: flavor_total.toFixed(2),
          label: defaultTexts.submenuFlavors,
          color: pink[500],
        })
      }

      if (volume_total !== 0) {
        resources_chart_data.push({
          value: volume_total.toFixed(2),
          label: defaultTexts.submenuVolumeTypes,
          color: purple[500],
        })
      }

      if (backup_total !== 0) {
        resources_chart_data.push({
          value: backup_total.toFixed(2),
          label: defaultTexts.submenuBackups,
          color: cyan[500],
        })
      }

      if (snapshot_total !== 0) {
        resources_chart_data.push({
          value: snapshot_total.toFixed(2),
          label: defaultTexts.snapshots,
          color: teal[500],
        })
      }

      if (vpn_total !== 0) {
        resources_chart_data.push({
          value: vpn_total.toFixed(2),
          label: defaultTexts.vpnsLabel,
          color: lightGreen[500],
        })
      }

      if (router_total !== 0) {
        resources_chart_data.push({
          value: router_total.toFixed(2),
          label: defaultTexts.routers,
          color: lime[500],
        })
      }

      if (floating_ip_total !== 0) {
        resources_chart_data.push({
          value: floating_ip_total.toFixed(2),
          label: defaultTexts.floatingIPsTabButtonText,
          color: deepOrange[500],
        })
      }

      if (fwaas_rule_total !== 0) {
        resources_chart_data.push({
          value: fwaas_rule_total.toFixed(2),
          label: defaultTexts.firewallRulesTabButtonText,
          color: orange[500],
        })
      }

      if (image_total !== 0) {
        resources_chart_data.push({
          value: image_total.toFixed(2),
          label: defaultTexts.submenuImages,
          color: brown[500],
        })
      }

      if (zone_total !== 0) {
        resources_chart_data.push({
          value: zone_total.toFixed(2),
          label: defaultTexts.submenuZones,
          color: brown[600],
        })
      }

      setResourcesChartData(resources_chart_data)
      setServicesChartData(service_chart_data)
    } else {
      setResourcesChartData([])
      setServicesChartData([])
    }
  }, [selectedResources, defaultTexts])

  useEffect(() => {
    setCalculatorServices(calculatorServicesSchema.filter(item => item.value !== 'dns_designate' || zoneData.length > 0))
  }, [zoneData])
  
  return (
    <>
      <Stack
        direction={ width >= 900 ? 'row' : 'column' }
        justifyContent="flex-start"
        alignItems={ width >= 900 ? 'center' : 'flex-start' }
        spacing={4}
        sx={{
          width: '100%',
          marginBottom: '20px',
          paddingTop: '10px',
        }}
      >
        <Box sx={labelStyle}>{defaultTexts.costPlanningLabel}</Box>
        {
          regionList.length > 0 &&
          <CustomSelectField
            items={regionList}
            currentValue={region}
            setCurrentValue={setRegion}
            self_item_titles={true}
            label={defaultTexts.selectRegionLabel}
            empty={true}
            error={region ? false : true}
            size='small'
          />
        }        
      </Stack>

      <Grid container>
        <Grid xs={12} lg={6}>

          <Box
            sx={{
              maxWidth: width >= 900 ? undefined : `${width / 1.5}px`,
              marginBottom: '20px',
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {
                calculatorServices.map((item, index) => (
                  <Tab key={index} value={item.value} label={item.label} />
                ))
              }
            </Tabs>
          </Box>
          
          <Box sx={{ maxWidth: width >= 900 ? undefined : `${width / 1.5}px` }}>
            {
              currentTab === 'compute_nova' &&
              <>
                <Grid container spacing={2}>
                  <Grid xs={12} lg={9}>
                    <Box sx={{ width: width >= 900 ? undefined : `${width / 1.5}px`, }}>
                      <CustomSelectField
                        items={flavorsList}
                        currentValue={selectedFlavor}
                        setCurrentValue={setSelectedFlavor}
                        self_item_titles={true}
                        label={defaultTexts.selectFlavorLabel}
                        empty={true}
                        size='small'
                        disabled={calculatorDisabled}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={8} lg={2}>
                    <Box>
                      <CustomTextField 
                        currentValue={flavorCount}
                        setCurrentValue={(event) => setFlavorCount(parseInt(event.target.value))}
                        label={defaultTexts.countLabel}
                        size="small"
                        type="number"
                        min={1}
                        disabled={calculatorDisabled}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={4} lg={1}>
                    <IconButton
                      sx={{
                        color: theme.palette.success.main,
                        marginTop: '-6px',
                      }}
                      onClick={handleAddFlavor}
                    >
                      <AddCircleOutlineIcon fontSize='large' />
                    </IconButton>
                  </Grid>
                </Grid>

                <Box sx={{ marginTop: '40px' }}>
                  {
                    selectedFlavors.map((item, index) => (
                      <Box key={index}>
                        <Grid container spacing={2}>
                          <Grid xs={12} lg={9}>
                            <Box sx={resourceLabelStyle}>{getLabelFromFlavorList(item.id)}</Box>
                            <Box sx={resourcePriceStyle}>{getPriceFromFlavorList(item.id)} {currencyName} / {defaultTexts.hourLabel}</Box>     
                          </Grid>
                          <Grid xs={8} lg={2}>
                            <Box sx={resourceCountStyle}>{item.count} {defaultTexts.instances}</Box>
                          </Grid>
                          <Grid xs={4} lg={1}>
                            <IconButton
                              sx={{
                                color: theme.palette.error.main,
                                marginTop: '-6px',
                              }}
                              onClick={() => handleRemoveResource(item.id)}
                            >
                              <DeleteIcon fontSize='large' />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Divider sx={{ marginBottom: '10px' }} />
                      </Box>            
                    ))
                  }
                </Box>
              </>
            }

            {
              currentTab === 'volumes_cinder' &&
              <>
                <Grid container spacing={2}>
                  <Grid xs={12} lg={7}>
                    <Box>
                      <CustomSelectField
                        items={volumesList}
                        currentValue={selectedVolume}
                        setCurrentValue={setSelectedVolume}
                        self_item_titles={true}
                        label={defaultTexts.selectVolumeTypeLabel}
                        empty={true}
                        size='small'
                        disabled={calculatorDisabled}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={4} lg={2}>
                    <Box>
                      <CustomTextField 
                        currentValue={volumeSize}
                        setCurrentValue={(event) => setVolumeSize(parseInt(event.target.value))}
                        label={defaultTexts.sizeInGBFormFieldLabel}
                        size="small"
                        type="number"
                        min={1}
                        disabled={calculatorDisabled}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={4} lg={2}>
                    <Box>
                      <CustomTextField 
                        currentValue={volumeCount}
                        setCurrentValue={(event) => setVolumeCount(parseInt(event.target.value))}
                        label={defaultTexts.countLabel}
                        size="small"
                        type="number"
                        min={1}
                        disabled={calculatorDisabled}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={4} lg={1}>
                    <IconButton
                      sx={{
                        color: theme.palette.success.main,
                        marginTop: '-6px',
                      }}
                      onClick={handleAddVolume}
                    >
                      <AddCircleOutlineIcon fontSize='large' />
                    </IconButton>
                  </Grid>
                </Grid>

                <Box sx={{ marginTop: '40px' }}>
                  {
                    selectedVolumes.map((item, index) => (
                      <Box key={index}>
                        <Grid container spacing={2}>
                          <Grid xs={12} lg={7}>
                            <Box sx={resourceLabelStyle}>{getLabelFromVolumeList(item.id)}</Box>
                            <Box sx={resourcePriceStyle}>{getPriceFromVolumeList(item.id)} {currencyName} / {defaultTexts.hourLabel}</Box>                
                          </Grid>
                          <Grid xs={12} lg={2}>
                            <Box sx={resourceLabelStyle}>{item.size} {defaultTexts.gblabel}</Box>
                          </Grid>
                          <Grid xs={8} lg={2}>
                            <Box sx={resourceCountStyle}>{item.count} {defaultTexts.volumes}</Box>
                          </Grid>
                          <Grid xs={4} lg={1}>
                            <IconButton
                              sx={{
                                color: theme.palette.error.main,
                                marginTop: '-6px',
                              }}
                              onClick={() => handleRemoveResource(item.id)}
                            >
                              <DeleteIcon fontSize='large' />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Divider sx={{ marginBottom: '10px' }} />
                      </Box>            
                    ))
                  }
                </Box>

                <Box sx={{ marginTop: '40px' }}>
                  <Grid container spacing={2}>
                    <Grid lg={3}>
                      <Box>
                        <CustomCheckboxField
                          currentValue={backupsChecked}
                          setCurrentValue={handleChangeBackupChecked}
                          label={defaultTexts.submenuBackups}
                          required={false}
                          disabled={calculatorDisabled}
                        />
                      </Box>
                    </Grid>
                    {
                      backupsChecked &&
                      <>
                        <Grid lg={2}>
                          <Box>
                            <CustomTextField 
                              currentValue={backupSize}
                              setCurrentValue={handleChangeBackupSize}
                              label={defaultTexts.sizeInGBFormFieldLabel}
                              size="small"
                              type="number"
                              min={1}
                            />
                          </Box>
                        </Grid>
                        <Grid lg={2}>
                          <Box>
                            <CustomTextField 
                              currentValue={backupCount}
                              setCurrentValue={handleChangeBackupCount}
                              label={defaultTexts.countLabel}
                              size="small"
                              type="number"
                              min={1}
                            />
                          </Box>
                        </Grid>
                      </>
                    }             
                  </Grid>
                </Box>

                <Box sx={{ marginTop: '20px' }}>
                  <Grid container spacing={2}>
                    <Grid lg={3}>
                      <Box>
                        <CustomCheckboxField
                          currentValue={snapshotsChecked}
                          setCurrentValue={handleChangeSnapshotChecked}
                          label={defaultTexts.submenuSnapshots}
                          required={false}
                          disabled={calculatorDisabled}
                        />
                      </Box>
                    </Grid>
                    {
                      snapshotsChecked &&
                      <>
                        <Grid lg={2}>
                          <Box>
                            <CustomTextField 
                              currentValue={snapshotSize}
                              setCurrentValue={handleChangeSnapshotSize}
                              label={defaultTexts.sizeInGBFormFieldLabel}
                              size="small"
                              type="number"
                              min={1}
                            />
                          </Box>
                        </Grid>
                        <Grid lg={2}>
                          <Box>
                            <CustomTextField 
                              currentValue={snapshotCount}
                              setCurrentValue={handleChangeSnapshotCount}
                              label={defaultTexts.countLabel}
                              size="small"
                              type="number"
                              min={1}
                            />
                          </Box>
                        </Grid>
                      </>
                    }             
                  </Grid>
                </Box>
              </>
            }

            {
              currentTab === 'networks_neutron' &&
              <>
                <Grid container spacing={2}>
                  <Grid xs={12} lg={6}>
                    <Box>
                      <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-start'
                        spacing={1}
                        sx={{ width: '100%' }}
                      >
                        <Box sx={resourceLabelStyle}>{defaultTexts.submenuVPNaaS}</Box>
                        <IconButton
                          sx={{
                            color: theme.palette.success.main,
                            marginTop: '-6px',
                          }}
                          onClick={handleAddVpn}
                        >
                          <AddCircleOutlineIcon fontSize='large' />
                        </IconButton>
                      </Stack>
                    </Box>
                    <Box sx={{ marginBottom: '10px' }}>
                      <CustomSelectField
                        items={ikePolicyList}
                        currentValue={selectedIkePolicy}
                        setCurrentValue={setSelectedIkePolicy}
                        self_item_titles={true}
                        label={defaultTexts.ikePolicyEncryptionAlgorithmLabel}
                        empty={true}
                        size='small'
                        disabled={calculatorDisabled}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                    <Box>
                      <CustomSelectField
                        items={ipsecPolicyList}
                        currentValue={selectedIpsecPolicy}
                        setCurrentValue={setSelectedIpsecPolicy}
                        self_item_titles={true}
                        label={defaultTexts.ipsecPolicyEncryptionAlgorithmLabel}
                        empty={true}
                        size='small'
                        disabled={calculatorDisabled}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <Box sx={{ marginBottom: '9px' }}>
                      <Grid container>
                        <Grid xs={6} lg={6}>
                          <Box>
                            <CustomCheckboxField
                              currentValue={routersChecked}
                              setCurrentValue={handleChangeRouterChecked}
                              label={defaultTexts.routers}
                              required={false}
                              disabled={calculatorDisabled}
                            />
                          </Box>
                        </Grid>
                        {
                          routersChecked &&
                          <>
                            <Grid xs={6} lg={4}>
                              <Box>
                                <CustomTextField 
                                  currentValue={routerCount}
                                  setCurrentValue={handleChangeRouterCount}
                                  label={defaultTexts.countLabel}
                                  size="small"
                                  type="number"
                                  min={1}
                                />
                              </Box>
                            </Grid>
                          </>
                        }
                      </Grid>
                    </Box>
                    <Box sx={{ marginBottom: '9px' }}>
                      <Grid container>
                        <Grid xs={6} lg={6}>
                          <Box>
                            <CustomCheckboxField
                              currentValue={floatingIpChecked}
                              setCurrentValue={handleChangeFloatingIpChecked}
                              label={defaultTexts.floatingIPsTabButtonText}
                              required={false}
                              disabled={calculatorDisabled}
                            />
                          </Box>
                        </Grid>
                        {
                          floatingIpChecked &&
                          <>
                            <Grid xs={6} lg={4}>
                              <Box>
                                <CustomTextField 
                                  currentValue={floatingIpCount}
                                  setCurrentValue={handleChangeFloatingIpCount}
                                  label={defaultTexts.countLabel}
                                  size="small"
                                  type="number"
                                  min={1}
                                />
                              </Box>
                            </Grid>
                          </>
                        }
                      </Grid>
                    </Box>
                    <Box>
                      <CustomSelectField
                        items={firewallRulesList}
                        currentValue={selectedFirewallRule}
                        setCurrentValue={handleChangeFirewallRule}
                        self_item_titles={true}
                        label={defaultTexts.selectFirewallRulesRangeLabel}
                        empty={true}
                        size='small'
                        disabled={calculatorDisabled}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  </Grid>                  
                </Grid>

                <Box sx={{ marginTop: '40px' }}>
                  {
                    selectedVpns.map((item, index) => (
                      <Box key={index}>
                        <Grid container spacing={2}>
                          <Grid xs={12} lg={5}>
                            <Box sx={resourceLabelStyle}>{defaultTexts.submenuVPNaaS} {index + 1}</Box>
                            <Box sx={resourcePriceStyle}>{(item.price).toFixed(4)} {currencyName} / {defaultTexts.hourLabel}</Box>            
                          </Grid>
                          <Grid xs={6} lg={3}>
                            <Box sx={resourceDescriptionStyle}>{defaultTexts.ikeEncryptionLabel}</Box>
                            <Box sx={resourceDescriptionStyle}>{defaultTexts.ipsecEncryptionLabel}</Box>            
                          </Grid>
                          <Grid xs={6} lg={3}>
                            <Box sx={resourceLabelStyle}>{getLabelFromIkePolicyList(item.ike_id)}</Box>
                            <Box sx={resourceLabelStyle}>{getLabelFromIpsecPolicyList(item.ipsec_id)}</Box>            
                          </Grid>
                          <Grid xs={12} lg={1}>
                            <IconButton
                              sx={{
                                color: theme.palette.error.main,
                                marginTop: '-6px',
                              }}
                              onClick={() => handleRemoveVpn({ ike_id: item.ike_id, ipsec_id: item.ipsec_id })}
                            >
                              <DeleteIcon fontSize='large' />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Divider sx={{ marginBottom: '10px' }} />
                      </Box>            
                    ))
                  }
                </Box>
              </>
            }

            {
              currentTab === 'images_glance' &&
              <>
                <Grid container spacing={2}>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <CustomCheckboxField
                        currentValue={imagesChecked}
                        setCurrentValue={handleChangeImageChecked}
                        label={defaultTexts.submenuImages}
                        required={false}
                        disabled={calculatorDisabled}
                      />
                    </Box>
                  </Grid>
                  {
                    imagesChecked &&
                    <>
                      <Grid xs={12} lg={2}>
                        <Box>
                          <CustomTextField 
                            currentValue={imageSize}
                            setCurrentValue={handleChangeImageSize}
                            label={defaultTexts.sizeInBytesFormFieldLabel}
                            size="small"
                            type="number"
                            min={1}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={12} lg={2}>
                        <Box>
                          <CustomTextField 
                            currentValue={imageCount}
                            setCurrentValue={handleChangeImageCount}
                            label={defaultTexts.countLabel}
                            size="small"
                            type="number"
                            min={1}
                          />
                        </Box>
                      </Grid>
                    </>
                  }             
                </Grid>
              </>
            }

            {
              currentTab === 'dns_designate' &&
              <>
                <Grid container spacing={2}>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <CustomCheckboxField
                        currentValue={zoneChecked}
                        setCurrentValue={handleChangeZoneChecked}
                        label={defaultTexts.submenuZones}
                        required={false}
                        disabled={calculatorDisabled}
                      />
                    </Box>
                  </Grid>
                  {
                    zoneChecked &&
                    <>
                      <Grid xs={6} lg={4}>
                        <Box>
                          <CustomTextField 
                            currentValue={zoneCount}
                            setCurrentValue={handleChangeZoneCount}
                            label={defaultTexts.countLabel}
                            size="small"
                            type="number"
                            min={1}
                          />
                        </Box>
                      </Grid>
                    </>
                  }
                </Grid>
              </>
            }
          </Box>
        </Grid>
        <Grid xs={12} lg={5} lgOffset={1}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            spacing={2}
          >
            {
              width >= 900 &&
              <>
                {
                  selectedResources.length === 0 ?
                  <Gauge
                    width={300}
                    height={300}
                    value={0}
                    sx={{ [`& .${gaugeClasses.valueText}`]: { fontSize: '30px' } }}
                  /> :
                  <Box sx={{ width: '400px', height: '300px' }}>
                    <PieChart
                      series={[
                        {
                          data: servicesChartData,
                          outerRadius: 60,
                          arcLabelMinAngle: 15,
                          cx: 190,
                        },
                        {
                          data: resourcesChartData,
                          innerRadius: 60,
                          arcLabel: (item) => `${item.value}`,
                          arcLabelMinAngle: 15,
                          cx: 190,
                        },
                      ]}
                      slotProps={{ legend: { hidden: true } }}
                      sx={{ [`& .${pieArcLabelClasses.root}`]: pieChartLabelStyle }}
                    />
                  </Box>
                }
              </>
            }
            
            <Box>
              <Box sx={totalAmountStyle}>{calculateTotalAmount()} {currencyName}</Box>
              <Box sx={monthlyLabelStyle}>{defaultTexts.monthlyLabel}</Box>
            </Box>            
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default PlanningCalculator