export const limitsDataSchema = [
    {
        field_key: "resource_name",
        is_id_field: true,
        field_type: "string", 
        label: "resourceNameFormFieldLabel"
    },
    {
        field_key: "limit",
        is_id_field: false,
        field_type: "string", 
        label: "quotaLimitsFormFieldLabel"
    }
]

export const dnsLimitsUpdateDataForm = [
    {
        field_key: "api_export_size",
        field_type: "integer", 
        required: false,
        label: "apiExportSizeFormFieldLabel",
        min: 0
    },
    {
        field_key: "recordset_records",
        field_type: "integer", 
        required: false,
        label: "recordsetRecordsFormFieldLabel",
        min: 0
    },
    {
        field_key: "zone_records",
        field_type: "integer", 
        required: false,
        label: "zoneRecordsFormFieldLabel",
        min: 0
    },
    {
        field_key: "zone_recordsets",
        field_type: "integer", 
        required: false,
        label: "zoneRecordsetsFormFieldLabel",
        min: 0
    },
    {
        field_key: "zones",
        field_type: "integer", 
        required: false,
        label: "zonesFormFieldLabel",
        min: 0
    }
]