export const zoneDataSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel",
        sort_key: 'name',
    },
    // {
    //     field_key: "project_id",
    //     is_id_field: false,
    //     field_type: "string", 
    //     label: "projectFormFieldLabel"
    // },
    {
         field_key: "type",
         is_id_field: false,
         field_type: "string", 
         label: "typeFormFieldLabel"
    },
    {
        field_key: "pool_id",
        is_id_field: false,
        field_type: "string", 
        label: "poolFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel",
        sort_key: 'status',
    },
    {
        field_key: "action",
        is_id_field: false,
        field_type: "string", 
        label: "actionInProgressFormFieldLabel"
    },
    {
        field_key: "email",
        is_id_field: false,
        field_type: "string", 
        label: "emailFormFieldLabel"
    },
    {
        field_key: "ttl",
        is_id_field: false,
        field_type: "string", 
        label: "ttlFormFieldLabel",
        sort_key: 'ttl',
    },
    {
        field_key: "shared",
        is_id_field: false,
        field_type: "bool",
        label: "sharedFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const zoneDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "email",
        field_type: "string", 
        required: true, 
        label: "emailFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "ttl",
        field_type: "integer", 
        required: false,
        label: "ttlFormFieldLabel",
        default_value: 3600,
        min: 0
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false, 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "type",
        field_type: "select", 
        required: false, 
        label: "typeFormFieldLabel",
        default_value: "PRIMARY",
        self_item_titles: false,
        multiple: false,
        empty: false,
        items: [
            {
                keyword: "formValuePrimary",
                value: "PRIMARY",
                default: true
            },
            {
                keyword: "formValueSecondary",
                value: "SECONDARY",
                default: false
            }
        ]
    }
]

export const masterServersForm = [
    {
        field_key: "masters",
        field_type: "string",
        required: false,
        label: "masterFormFieldLabel",
        error_label: "requiredFormFieldError"
    }
]

export const zoneUpdateForm = [
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "email",
        field_type: "string", 
        required: false,
        label: "emailFormFieldLabel"
    },
    {
        field_key: "ttl",
        field_type: "integer", 
        required: false,
        label: "ttlFormFieldLabel",
        min: 0
    }
]

export const zonesFilterMenu = [
    {
        keyword: "statusFormFieldLabel", 
        value: "status",
        type: "select",
        self_item_titles: false,
        multiple: false,
        items: [
            {
                keyword: "formValueActive", 
                value: "active", 
                default: true
            },
            {
                keyword: "formValuePending", 
                value: "pending", 
                default: false
            },
            {
                keyword: "formValueError", 
                value: "error", 
                default: false
            }
        ]
    },
    {
        keyword: "typeFormFieldLabel", 
        value: "type",
        type: "select",
        self_item_titles: false,
        multiple: false,
        items: [
            {
                keyword: "formValuePrimary", 
                value: "PRIMARY", 
                default: true
            },
            {
                keyword: "formValueSecondary", 
                value: "SECONDARY", 
                default: false
            }
        ]
    },
    {
        keyword: "nameFormFieldLabel", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "emailFormFieldLabel", 
        value: "email", 
        type: "text"
    }
]

export const zonesSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "ttl",
        label: "ttlFormFieldLabel"
    },
    {
        field_key: "status",
        label: "statusFormFieldLabel"
    }
]

export const zoneRecordUpdateDataForm = [
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "ttl",
        field_type: "integer", 
        required: false,
        label: "ttlFormFieldLabel",
        min: 0
    }
]

export const zoneRecordForm = [
    {
        field_key: "record",
        field_type: "string",
        required: false,
        label: "recordFormFieldLabel"
    }
]

export const zoneRecordsetForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false, 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "ttl",
        field_type: "integer", 
        required: false,
        label: "ttlFormFieldLabel",
        default_value: 3600,
        min: 0
    },
    {
        field_key: "type",
        field_type: "select", 
        required: false, 
        label: "typeFormFieldLabel",
        default_value: "A",
        self_item_titles: false,
        multiple: false,
        empty: false,
        items: [
            {
                keyword: "formValueRecordsetAType",
                value: "A",
                default: true
            },
            {
                keyword: "formValueRecordsetAAAAType",
                value: "AAAA",
                default: false
            },
            {
                keyword: "formValueRecordsetCNAMEType",
                value: "CNAME",
                default: false
            },
            {
                keyword: "formValueRecordsetMXType",
                value: "MX",
                default: false
            },
            {
                keyword: "formValueRecordsetNSType",
                value: "NS",
                default: false
            },
            {
                keyword: "formValueRecordsetPTRType",
                value: "PTR",
                default: false
            },
            {
                keyword: "formValueRecordsetSPFType",
                value: "SPF",
                default: false
            },
            {
                keyword: "formValueRecordsetSRVType",
                value: "SRV",
                default: false
            },
            {
                keyword: "formValueRecordsetSSHFPType",
                value: "SSHFP",
                default: false
            },
            {
                keyword: "formValueRecordsetTXTType",
                value: "TXT",
                default: false
            },
            {
                keyword: "formValueRecordsetCAAType",
                value: "CAA",
                default: false
            },
        ]
    }
]

export const poolDataSchema = [
    {
        field_key: "name",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "ns_records",
        is_id_field: false,
        field_type: "list", 
        label: "nsRecordsFormFieldLabel"
    },
    {
        field_key: "attributes",
        is_id_field: false,
        field_type: "json", 
        label: "attributesFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]


export const serviceStatusSchema = [
    {
        field_key: "hostname",
        is_id_field: true,
        field_type: "string", 
        label: "hostnameFormFieldLabel"
    },
    {
        field_key: "service_name",
        is_id_field: false,
        field_type: "string", 
        label: "serviceNameFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "capabilities",
        is_id_field: false,
        field_type: "json", 
        label: "capabilitiesFormFieldLabel"
    },
    {
        field_key: "stats",
        is_id_field: false,
        field_type: "json", 
        label: "statsFormFieldLabel"
    },
    {
        field_key: "heartbeated_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "lastHeartbeatFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]